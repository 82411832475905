import dayjs, { Dayjs } from "dayjs";
import React, {
  ChangeEvent,
  Dispatch,
  ReactElement,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import getFixtures from "../api/PotBuilder/getFixtures";
import useAsyncEffect from "use-async-effect";
import AccordionDrop from "../components/Accordion";
import utc from "dayjs/plugin/utc";
import {
  hardcodedCrests,
  hardcodedPayoutRatioSelector,
} from "../config/hardcoded data/hardcodedCrests";
import {
  AlertTextPot,
  FormErrors,
  PotBuilderFormHeaders,
  PotBuilderFormOptions,
  PotCreatorForm,
} from "../config/Text";
import {
  currencyValidation,
  nonZeroCurrencyValidation,
  numberValidation,
  payoutStructureValidation,
  potCodeValidation,
} from "../config/Validation";
import { LoginContext } from "../context/LoginContext";
import { Competition, Fixture } from "../interfaces/Fixture";
import { LoginInfoInterface } from "../interfaces/Login";
import { menuItem } from "../interfaces/MenuItem";
import {
  alertBanner,
  clearAll,
  handleClick,
  handleClickAway,
  handleDropdownChange,
  handleFixtureChange,
  handleFixtureClickAway,
  handleRadioChange,
  handleTextChange,
  handleTextClickAway,
  handleTimeChange,
} from "./Main";
import DropDownField from "../components/DropDownField";
import { Button, Grid } from "@mui/material";
import TextField from "../components/TextField";
import RadioField from "../components/Radio";
import TimeField from "../components/TimeField";
import Colors from "../config/Colors";
import { LoadingButton } from "@mui/lab";
import { Selector } from "../interfaces/Selector";
import postSelectorFormData from "../api/Selector/postSelectorFormData";
import Checkbox from "../components/Checkbox";

const SelectorScreen = (): ReactElement => {
  // value hooks
  const [potType, setPotType] = useState<boolean>(true);
  const [leftIcon, setLeftIcon] = useState<string>("");
  const [rightIcon, setRightIcon] = useState<string>("");
  const [potName, setPotName] = useState<string>("");
  const [entryFee, setEntryFee] = useState<string>("");
  const [payout, setPayout] = useState<string>("");
  const [noOfWinners, setNoOfWinners] = useState<string>("");
  const [winningsMoney, setWinningsMoney] = useState<boolean>(true);
  const [maxEntrants, setMaxEntrants] = useState<string>("");
  const [maxEntrantsPerUser, setMaxEntrantsPerUser] = useState<string>("");
  const [fixtureIds, setFixtureIds] = useState<number[]>([]);
  const [potCode, setPotCode] = useState<string>("");
  const [potStartTime, setPotStartTime] = useState<Dayjs | null>(null);
  const [lastFixtureStartTime, setLastFixtureStartTime] =
    useState<Dayjs | null>(null);
  const [displayTime, setDisplayTime] = useState<Dayjs | null>(null);
  const [jackpotPayoutAmount, setJackpotPayoutAmount] = useState<string>("");
  const [jackpotPointsThreshold, setJackpotPointsThreshold] =
    useState<string>("");

  // validation hooks
  const [potNameError, setPotNameError] = useState<string>("");
  const [entryFeeError, setEntryFeeError] = useState<string>("");
  const [payoutError, setPayoutError] = useState<string>("");
  const [noOfWinnersError, setNoOfWinnersError] = useState<string>("");
  const [maxEntrantsError, setMaxEntrantsError] = useState<string>("");
  const [maxEntrantsPerUserError, setMaxEntrantsPerUserError] =
    useState<string>("");
  const [potCodeError, setPotCodeError] = useState<string>("");
  const [fixtureError, setFixtureError] = useState<string>("");
  const [potStartTimeError, setPotStartTimeError] = useState<string>("");
  const [displayTimeError, setDisplayTimeError] = useState<string>("");
  const [payoutStructureError, setPayoutStructureError] = useState<string>("");
  const [jackpotPayoutAmountError, setJackpotPayoutAmountError] =
    useState<string>("");
  const [jackpotPointsThresholdError, setJackpotPointsThresholdError] =
    useState<string>("");

  //text hooks
  const [potNameClicked, setPotNameClicked] = useState<boolean | null>();
  const [entryFeeClicked, setEntryFeeClicked] = useState<boolean | null>();
  const [payoutClicked, setPayoutClicked] = useState<boolean | null>();
  const [noOfWinnersClicked, setNoOfWinnersClicked] = useState<
    boolean | null
  >();
  const [potCodeClicked, setPotCodeClicked] = useState<boolean | null>();
  const [payoutStructureClicked, setPayoutStructureClicked] = useState<
    boolean | null
  >();
  const [maxEntrantsPerUserClicked, setMaxEntrantsPerUserClicked] = useState<
    boolean | null
  >();

  //fixture hooks
  const [checkedTeamId, setCheckedTeamId] = useState<number[]>([]);
  const [fixtureClicked, setFixtureClicked] = useState<boolean | null>();
  const [fullFixtureInfoArray, setFullFixtureInfoArray] = useState<Fixture[]>(
    []
  );
  const [sortedFixtures, setSortedFixtures] = useState<Fixture[]>([]);

  //dropdown hooks
  const [dropdownValueLeft, setDropdownValueLeft] = useState<string>("");
  const [dropdownValueRight, setDropdownValueRight] = useState<string>("");
  const [dropdownValueWinners, setDropdownValueWinners] = useState<string>("");
  const [dropdownLeftClicked, setDropdownLeftClicked] = useState<
    boolean | null
  >();
  const [dropdownRightClicked, setDropdownRightClicked] = useState<
    boolean | null
  >();

  //radio hooks
  const [winningsMoneyRadio, setWinningsMoneyRadio] = useState("Money");
  const [potTypeRadio, setPotTypeRadio] = useState("Full Time Result");

  //time hooks
  const [potStartTimeClicked, setPotStartTimeClicked] = useState<
    boolean | null
  >();

  //field filled hooks
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertErrorMessage, setAlertErrorMessage] = useState<string>("");

  //data hooks
  const [fixtureData, setFixtureData] = useState<Array<Competition>>([
    { name: "", fixtures: [] },
  ]);
  const [crestData, setCrestData] = useState<menuItem[]>([]);
  const [numberOfWinnersData, setNumberOfWinnersData] = useState<menuItem[]>(
    []
  );
  const [progress, setProgress] = useState<number>();

  const { loginInfo } = useContext<LoginInfoInterface>(LoginContext);

  dayjs.extend(utc);

  useEffect(() => {
    if (fullFixtureInfoArray.length === 0) {
      return;
    }
    if (fullFixtureInfoArray.length > 0) {
      const sortedArray: Fixture[] = fullFixtureInfoArray.sort((a, b) =>
        dayjs(a.kickOffDateTimeUtc).isAfter(dayjs(b.kickOffDateTimeUtc))
          ? 1
          : -1
      );
      setSortedFixtures(sortedArray);
      if (sortedArray.length - 1) {
        const lastStartTime = dayjs.utc(
          sortedArray[sortedArray.length - 1].kickOffDateTimeUtc
        );

        setLastFixtureStartTime(lastStartTime);
      } else {
        const lastStartTime = dayjs.utc(sortedArray[0].kickOffDateTimeUtc);
        setLastFixtureStartTime(lastStartTime);
      }
    }
  }, [fullFixtureInfoArray]);

  useEffect(() => {
    if (sortedFixtures.length > 0) {
      const [fixture] = sortedFixtures;
      if (fixture) {
        const kickOff = dayjs(fixture.kickOffDateTimeUtc);

        const maxEntrantsPerUserNumber = "1";

        setPotStartTime(kickOff);
        setMaxEntrantsPerUser(maxEntrantsPerUserNumber);
        setDropdownValueLeft("999");
        setDropdownValueRight("999");
      }
    }
  }, [fixtureIds.length, sortedFixtures]);

  useEffect(() => {
    if (potTypeRadio === PotBuilderFormOptions.fullTimeResult) {
      setPotCode("FT-");
      setDropdownValueWinners("12");
      setNoOfWinners("60-12-8-6-4-3-2.5-2-1.5-1");
    } else if (potTypeRadio === PotBuilderFormOptions.bothTeamsToScore) {
      setPotCode("BTTS-");
      setDropdownValueWinners("11");
      setNoOfWinners("50-20-15-10-5");
    } else if (potTypeRadio === PotBuilderFormOptions.goalScorer) {
      setPotCode("GS-");
      setDropdownValueWinners("13");
      setNoOfWinners("50-20-15-10-5");
    }
  }, [potTypeRadio]);

  useEffect(() => {
    if (fixtureIds.length === 0 && fixtureClicked === true) {
      setFixtureError(FormErrors.fixture);
    } else {
      setFixtureError("");
    }
  }, [fixtureClicked, fixtureIds]);

  useEffect(() => {
    if (potName.length === 0 && potNameClicked === true) {
      setPotNameError(FormErrors.text);
    } else {
      setPotNameError("");
    }
  }, [potName, potNameClicked]);

  useEffect(() => {
    const entryFeeNumber = Number(entryFee);
    const isEntryFeeHigher = checkIfEntryFeeHigher();

    if (
      (entryFee.length !== 0 &&
        (entryFeeNumber < 1 || entryFeeNumber > 100) &&
        entryFeeNumber !== 0) ||
      (entryFeeClicked && entryFee.length === 0)
    ) {
      setEntryFeeError(FormErrors.entryFee);
    } else if (isEntryFeeHigher === true) {
      setEntryFeeError(FormErrors.entryFeeHigher);
    } else if (
      ((entryFee.match(currencyValidation) !== null &&
        entryFeeNumber >= 1 &&
        entryFeeNumber <= 100 &&
        entryFee.length !== 0) ||
        entryFeeNumber === 0) &&
      isEntryFeeHigher === false &&
      entryFee.length !== 0
    ) {
      setEntryFeeError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryFee, payout]);

  useEffect(() => {
    const payoutNumber = Number(payout);
    const isEntryFeeHigher = checkIfEntryFeeHigher();

    if (payout.length !== 0 && payoutNumber > 5000) {
      setPayoutError(FormErrors.payout);
    } else if (isEntryFeeHigher === true) {
      setEntryFeeError(FormErrors.entryFeeHigher);
    } else if (
      payout.match(currencyValidation) !== null &&
      payoutNumber < 5000 &&
      maxEntrants.length != 0 &&
      isEntryFeeHigher === false
    ) {
      setPayoutError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payout]);

  useEffect(() => {
    const maxEntrantsNumber = Number(maxEntrants);

    if (maxEntrants.length != 0 && maxEntrantsNumber < 2) {
      setMaxEntrantsError(FormErrors.text);
    } else if (
      maxEntrants.match(numberValidation) !== null ||
      maxEntrants.length === 0
    ) {
      setMaxEntrantsError("");
    }
  }, [maxEntrants]);

  useEffect(() => {
    const maxEntrantsPerUserNumber = Number(maxEntrantsPerUser);

    if (
      (maxEntrantsPerUser.length === 0 || maxEntrantsPerUserNumber < 1) &&
      maxEntrantsPerUserClicked === true
    ) {
      setMaxEntrantsPerUserError(FormErrors.text);
    } else if (maxEntrantsPerUserNumber < 1 || maxEntrantsPerUserNumber > 10) {
      setMaxEntrantsPerUserError(FormErrors.maxEntrantsPerUser);
    } else if (
      maxEntrantsPerUser.match(numberValidation) !== null &&
      maxEntrantsPerUserNumber > 0 &&
      maxEntrantsPerUserNumber < 11 &&
      maxEntrantsPerUser.length !== 0
    ) {
      setMaxEntrantsPerUserError("");
    }
  }, [maxEntrantsPerUser]);

  useEffect(() => {
    if (
      (potName.length === 0 || potName.length > 70) &&
      potNameClicked === true
    ) {
      setPotNameError(FormErrors.text);
    } else {
      setPotNameError("");
    }
  }, [potName, potNameClicked]);

  useEffect(() => {
    let payoutSum: number | null = null;
    let descendingOrder;

    if (
      noOfWinners &&
      noOfWinners.length > 0 &&
      noOfWinners.slice(-1) !== "-" &&
      noOfWinners.slice(-1) !== "." &&
      noOfWinners.match(payoutStructureValidation)
    ) {
      descendingOrder = checkDescendingOrder(noOfWinners);
      payoutSum = 0;

      const numbers = noOfWinners.split("-").map(Number);
      if (numbers.length > 0) {
        for (let i = 0; i < numbers.length; i++) {
          payoutSum = numbers[i] * 1000 + payoutSum;
        }
      }
    }

    if (noOfWinners.length === 0 && noOfWinnersClicked === true) {
      setNoOfWinnersError(FormErrors.icon);
    } else if (noOfWinners.slice(-1) !== "-") {
      setNoOfWinnersError("");
    }

    if (dropdownValueWinners.length === 0 && noOfWinnersClicked) {
      setNoOfWinnersError(FormErrors.icon);
    }

    if (
      (payoutSum === null ||
        payoutSum !== 100000 ||
        noOfWinners.length === 0) &&
      payoutStructureClicked === true
    ) {
      setPayoutStructureError(FormErrors.payoutStructure);
    } else if (descendingOrder === false) {
      setPayoutStructureError(FormErrors.descendingOrder);
    } else if (
      noOfWinners.slice(-1) !== "-" &&
      descendingOrder === true &&
      payoutSum === 100000
    ) {
      setPayoutStructureError("");
    }
  }, [
    noOfWinners,
    noOfWinnersClicked,
    payoutStructureClicked,
    dropdownValueWinners.length,
  ]);

  useEffect(() => {
    if (
      (potCode.length === 0 || potCode.length > 25) &&
      potCodeClicked === true
    ) {
      setPotCodeError(FormErrors.text);
    } else if (potCode.length < 5) {
      setPotCodeError(FormErrors.starting);
    } else {
      setPotCodeError("");
    }
  }, [potCode, potCodeClicked]);

  useEffect(() => {
    const currentTime: Dayjs = dayjs();
    let isAfter = false;

    if (potStartTime && potStartTime.isAfter(currentTime)) {
      isAfter = true;
    }

    if (potStartTime === null && potStartTimeClicked === true) {
      setPotStartTimeError(FormErrors.time);
    } else if (potStartTime !== null && isAfter === false) {
      setPotStartTimeError(FormErrors.timeBefore);
    } else if (potStartTime !== null && isAfter === true) {
      setPotStartTimeError("");
    }
  }, [potStartTime, potStartTimeClicked]);

  useEffect(() => {
    const currentTime: Dayjs = dayjs();
    let isAfter = false;

    if (displayTime && displayTime.isAfter(currentTime)) {
      isAfter = true;
    }

    if (displayTime !== null && isAfter === false) {
      setDisplayTimeError(FormErrors.timeBefore);
    } else if (isAfter === true) {
      setDisplayTimeError("");
    }
  }, [displayTime]);

  useEffect(() => {
    if (jackpotPayoutAmount.length === 0 && jackpotPointsThreshold.length > 0) {
      setJackpotPayoutAmountError(FormErrors.jackpotPayoutAmount);
    } else if (
      jackpotPointsThreshold.length === 0 &&
      jackpotPayoutAmount.length > 0
    ) {
      setJackpotPointsThresholdError(FormErrors.jackpotPayoutAmount);
    } else if (
      (jackpotPayoutAmount.length === 0 &&
        jackpotPointsThreshold.length === 0) ||
      (jackpotPayoutAmount.length > 0 && jackpotPointsThreshold.length > 0)
    ) {
      setJackpotPointsThresholdError("");
      setJackpotPayoutAmountError("");
    }
  }, [jackpotPayoutAmount, jackpotPointsThreshold]);

  // decided whether there are any errors and set the button enabled/disabled based on it
  useEffect(() => {
    let isError =
      fixtureError === "" &&
      potNameError === "" &&
      entryFeeError === "" &&
      maxEntrantsError === "" &&
      payoutStructureError === "" &&
      payoutError === "" &&
      noOfWinnersError === "" &&
      potStartTimeError === "" &&
      displayTimeError === "" &&
      potCodeError === "" &&
      maxEntrantsPerUserError === "" &&
      fixtureIds.length !== 0 &&
      potName.length !== 0 &&
      entryFee.length !== 0 &&
      noOfWinners.length !== 0 &&
      payout.length !== 0 &&
      potCode.length !== 0 &&
      potStartTime !== null &&
      maxEntrantsPerUser.length !== 0 &&
      jackpotPayoutAmountError === "" &&
      jackpotPointsThresholdError === "";

    setSubmitDisabled(!isError);

    return () => {
      isError = false;
    };
  }, [
    fixtureError,
    potNameError,
    entryFeeError,
    maxEntrantsError,
    payoutStructureError,
    payoutError,
    potStartTimeError,
    noOfWinnersError,
    potCodeError,
    fixtureIds,
    potName,
    entryFee,
    noOfWinners,
    payout,
    potCode,
    potStartTime,
    displayTimeError,
    jackpotPayoutAmountError,
    jackpotPointsThresholdError,
  ]);

  useAsyncEffect(async () => {
    if (crestData.length === 0) {
      await handleSetData();
    }
  });

  const checkDescendingOrder = (str: string) => {
    if (str) {
      const numbers = str.split("-").map(Number);
      if (numbers) {
        for (let i = 1; i < numbers.length; i++) {
          if (numbers[i] && numbers[i - 1] && numbers[i] > numbers[i - 1]) {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  };

  const checkIfEntryFeeHigher = () => {
    const payoutNumber = Number(payout);
    const entryFeeNumber = Number(entryFee);

    if (entryFeeNumber > payoutNumber) {
      return true;
    } else {
      return false;
    }
  };

  const handleSetData = async () => {
    if (loginInfo === null || loginInfo === undefined) {
      return;
    }
    try {
      const data: Competition[] = await getFixtures();

      if (data) {
        setFixtureData(data);
        setCrestData(hardcodedCrests);
        setNumberOfWinnersData(hardcodedPayoutRatioSelector);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleAlertClose = (
    _event: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const checkForInvalidCharacter = (string: string) => {
    const validatedString = string.replace(/'/g, "''");
    return validatedString;
  };

  const onSubmit = async () => {
    setButtonLoading(true);

    const validatedPotName = checkForInvalidCharacter(potName);

    const tempData: Selector = {
      leftIcon,
      rightIcon,
      potName: validatedPotName,
      potType: potTypeRadio,
      potCode: potCode,
      entryFee,
      payoutStructure: noOfWinners,
      payout,
      potStartTime,
      lastFixtureStartTime,
      displayTime,
      fixtureIds,
      maxEntrants,
      maxEntrantsPerUser,
      winningsMoney,
      jackpotPayoutAmount,
      jackpotPointsThreshold,
    };

    setButtonLoading(false);
    try {
      setAlert("progress");
      setProgress(0);
      setAlertOpen(true);
      await postSelectorFormData(tempData);
      setProgress(50);
      setProgress(100);
      setButtonLoading(false);
      setAlert("success");
      setAlertOpen(true);
    } catch (err) {
      setButtonLoading(false);
      setAlertErrorMessage(AlertTextPot.error);
      setAlert("error");
      setAlertOpen(true);
      console.log(err);
    }
  };

  const submitButton = (): ReactElement => {
    const buttonStyle = {
      width: "100%",
      padding: "8px",
      backgroundColor: submitDisabled ? Colors.Base400 : Colors.Primary600,
      color: Colors.Base050,
    };

    if (buttonLoading === false) {
      return (
        <Button
          disabled={submitDisabled}
          sx={buttonStyle}
          onClick={() => {
            onSubmit();
          }}
        >
          Submit
        </Button>
      );
    } else {
      return (
        <LoadingButton loading variant="outlined" sx={buttonStyle}>
          Submit
        </LoadingButton>
      );
    }
  };

  return (
    <form>
      {alertBanner(
        alert,
        alertOpen,
        handleAlertClose,
        progress,
        alertErrorMessage
      )}
      <Grid
        container
        spacing={8}
        sx={{
          padding: "32px",
        }}
      >
        <Grid item xs={12} lg={6}>
          <div className={"H5BoldLeftSecondaryPressed"}>
            {PotBuilderFormHeaders.gameType}
          </div>
          <RadioField
            value={potTypeRadio}
            label={PotCreatorForm.winningsPaid}
            onChange={(event) => {
              handleRadioChange(
                event,
                [
                  PotBuilderFormOptions.fullTimeResult,
                  PotBuilderFormOptions.goalScorer,
                  PotBuilderFormOptions.bothTeamsToScore,
                ],
                setPotType,
                setPotTypeRadio
              );
            }}
            options={[
              PotBuilderFormOptions.fullTimeResult,
              PotBuilderFormOptions.goalScorer,
              PotBuilderFormOptions.bothTeamsToScore,
            ]}
          />
          <div className={"H5BoldLeftSecondaryPressed"}>
            {PotBuilderFormHeaders.fixtures}
          </div>
          <AccordionDrop
            data={fixtureData}
            handleClick={() => {
              handleClick(setFixtureClicked);
            }}
            handleClickAway={() => {
              handleFixtureClickAway(
                setFixtureError,
                fixtureIds,
                fixtureClicked
              );
            }}
            onChange={(event) => {
              handleFixtureChange(
                event,
                fixtureData,
                checkedTeamId,
                fixtureIds,
                fullFixtureInfoArray,
                setCheckedTeamId,
                setFixtureIds,
                setFullFixtureInfoArray
              );
            }}
            error={fixtureError}
            selectedItemIds={fixtureIds}
            checkedTeamIds={checkedTeamId}
          />
          <div className={"H5BoldLeftSecondaryPressed"}>
            {PotBuilderFormHeaders.naming}
          </div>
          <DropDownField
            dropdownValue={dropdownValueLeft}
            menuItems={crestData}
            onChange={(event) => {
              handleDropdownChange(
                event,
                crestData,
                setLeftIcon,
                setDropdownValueLeft
              );
            }}
            handleClickAway={() => {
              handleClickAway(
                dropdownValueLeft,
                setDropdownLeftClicked,
                dropdownLeftClicked,
                FormErrors.icon
              );
            }}
            handleClick={() => {
              handleClick(setDropdownLeftClicked);
            }}
            label={PotCreatorForm.leftIcon}
          />
          <DropDownField
            dropdownValue={dropdownValueRight}
            menuItems={crestData}
            onChange={(event) => {
              handleDropdownChange(
                event,
                crestData,
                setRightIcon,
                setDropdownValueRight
              );
            }}
            handleClickAway={() => {
              handleClickAway(
                dropdownValueRight,
                setDropdownRightClicked,
                dropdownRightClicked,
                FormErrors.icon
              );
            }}
            handleClick={() => {
              handleClick(setDropdownRightClicked);
            }}
            label={PotCreatorForm.rightIcon}
          />
          <div className={"H5BoldLeftSecondaryPressed"}>
            {PotBuilderFormHeaders.money}
          </div>
          <TextField
            value={potName}
            label={PotCreatorForm.potName}
            onChange={(event) => {
              handleTextChange(event, null, setPotName, setPotNameError, true);
            }}
            handleClick={() => {
              handleClick(setPotNameClicked);
            }}
            error={potNameError}
          />
          <TextField
            value={payout}
            label={PotCreatorForm.payout}
            onChange={(event) => {
              handleTextChange(
                event,
                nonZeroCurrencyValidation,
                setPayout,
                setPayoutError
              );
            }}
            handleClick={() => {
              handleClick(setPayoutClicked);
            }}
            handleClickAway={() => {
              handleTextClickAway(
                setPayoutError,
                payout,
                setPayoutClicked,
                payoutClicked,
                nonZeroCurrencyValidation,
                FormErrors.text
              );
            }}
            inputAdornment={"£"}
            error={payoutError}
          />
          <TextField
            value={entryFee}
            label={PotCreatorForm.entryFee}
            onChange={(event) => {
              handleTextChange(
                event,
                currencyValidation,
                setEntryFee,
                setEntryFeeError
              );
            }}
            handleClick={() => {
              handleClick(setEntryFeeClicked);
            }}
            inputAdornment={"£"}
            error={entryFeeError}
          />
          <TextField
            value={jackpotPayoutAmount}
            label={PotCreatorForm.jackpotPayoutAmount}
            required={true}
            onChange={(event) => {
              handleTextChange(
                event,
                currencyValidation,
                setJackpotPayoutAmount,
                setJackpotPayoutAmountError
              );
            }}
            inputAdornment={"£"}
            error={jackpotPayoutAmountError}
          />
          <TextField
            value={jackpotPointsThreshold}
            label={PotCreatorForm.jackpotPointsThreshold}
            required={true}
            onChange={(event) => {
              handleTextChange(
                event,
                numberValidation,
                setJackpotPointsThreshold,
                setJackpotPointsThresholdError
              );
            }}
            error={jackpotPointsThresholdError}
          />
          <DropDownField
            dropdownValue={dropdownValueWinners}
            menuItems={numberOfWinnersData}
            onChange={(event) => {
              handleDropdownChange(
                event,
                numberOfWinnersData,
                setNoOfWinners,
                setDropdownValueWinners,
                setNoOfWinnersError
              );
            }}
            handleClick={() => {
              handleClick(setNoOfWinnersClicked);
            }}
            error={noOfWinnersError ? noOfWinnersError : ""}
            label={PotCreatorForm.numberOfWinners}
          />
          <TextField
            value={noOfWinners}
            label={PotCreatorForm.payoutStructure}
            required={true}
            onChange={(event) => {
              handleTextChange(
                event,
                null,
                setNoOfWinners,
                setPayoutStructureError
              );
            }}
            handleClick={() => {
              handleClick(setPayoutStructureClicked);
            }}
            error={payoutStructureError}
          />
          <RadioField
            value={winningsMoneyRadio}
            label={PotCreatorForm.winningsPaid}
            onChange={(event) => {
              handleRadioChange(
                event,
                [PotBuilderFormOptions.money, PotBuilderFormOptions.freeBets],
                setWinningsMoney,
                setWinningsMoneyRadio
              );
            }}
            options={[
              PotBuilderFormOptions.money,
              PotBuilderFormOptions.freeBets,
            ]}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className={"H5BoldLeftSecondaryPressed"}>
            {PotBuilderFormHeaders.potInformation}
          </div>
          <TextField
            value={maxEntrants}
            label={PotCreatorForm.maxEntrants}
            required={true}
            onChange={(event) => {
              handleTextChange(
                event,
                numberValidation,
                setMaxEntrants,
                setMaxEntrantsError
              );
            }}
            error={maxEntrantsError}
          />
          <TextField
            value={maxEntrantsPerUser}
            label={PotCreatorForm.maxEntrantsPerUser}
            required={true}
            onChange={(event) => {
              handleTextChange(
                event,
                numberValidation,
                setMaxEntrantsPerUser,
                setMaxEntrantsPerUserError
              );
            }}
            handleClick={() => {
              handleClick(setMaxEntrantsPerUserClicked);
            }}
            error={maxEntrantsPerUserError}
          />
          <div className={"H5BoldLeftSecondaryPressed"}>
            {PotBuilderFormHeaders.gameInformation}
          </div>
          <TextField
            value={potCode}
            label={PotCreatorForm.potCode}
            onChange={(event) => {
              handleTextChange(
                event,
                potCodeValidation,
                setPotCode,
                setPotCodeError,
                true
              );
            }}
            handleClick={() => {
              handleClick(setPotCodeClicked);
            }}
            error={potCodeError}
          />
          <TimeField
            value={potStartTime}
            label={PotCreatorForm.potStartTime}
            onChange={(event) => {
              handleTimeChange(event, setPotStartTime);
            }}
            handleClick={() => {
              handleClick(setPotStartTimeClicked);
            }}
            dateOnly={false}
            error={potStartTimeError}
          />
          <TimeField
            value={displayTime}
            label={PotCreatorForm.displayTime}
            onChange={(event) => {
              handleTimeChange(event, setDisplayTime);
            }}
            dateOnly={false}
            error={displayTimeError}
          />
          <br />
          {submitButton()}
          <br />
          <br />
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              padding: "8px",
            }}
            onClick={() => {
              clearAll();
            }}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SelectorScreen;
