import { Numbers } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  UserTransaction,
  UserReferral,
  UserPots,
} from "../interfaces/UserData";

interface PaginationTable {
  records: UserTransaction[] | UserReferral[] | UserPots[];
  skip: number;
  setSkip: React.Dispatch<React.SetStateAction<number>>;
  getValues: () => any[];
  columns: GridColDef[];
}

const PaginationTable = ({
  records,
  skip,
  setSkip,
  getValues,
  columns,
}: PaginationTable) => {
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);
  const [previousDisabled, setPreviousDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (!!records && records.length < 50) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [records, skip]);

  useEffect(() => {
    if (skip === 0) {
      setPreviousDisabled(true);
    } else {
      setPreviousDisabled(false);
    }
  }, [previousDisabled, skip]);

  const nextButton = () => {
    setSkip(skip + 50);
  };

  const previousButton = () => {
    if (skip !== 0) {
      setSkip(skip - 50);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: "20px",
        }}
      >
        <DataGrid
          rows={getValues()}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          disableRowSelectionOnClick
        />

        <Button disabled={nextDisabled} onClick={nextButton}>
          Load Next 50
        </Button>
        <Button disabled={previousDisabled} onClick={previousButton}>
          Load Previous 50
        </Button>
      </Box>
    </>
  );
};

export default PaginationTable;
