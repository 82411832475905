import type { LoginContextInterface } from "../../interfaces/Login";
import { potBuilderURLs } from "../url";

const getFixtures = async () => {
  const url = potBuilderURLs.getFixtures;
  try {
    const token = localStorage.getItem("loginInfo");
    if (token !== null) {
      const storedAccessToken: LoginContextInterface = JSON.parse(token);
      if (storedAccessToken.accessToken != null) {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${storedAccessToken.accessToken}`,
          },
        });

        try {
          const fixturesResponse = await response.json();
          return fixturesResponse;
        } catch (err) {
          console.log(err);
        }
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export default getFixtures;
