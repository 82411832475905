import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import type { MouseEventHandler, ReactElement } from "react";
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  Box,
  FormHelperText,
  ClickAwayListener,
} from "@mui/material";
import dayjs from "dayjs";

import Colors from "../config/Colors";
import type { Fixture, Competition } from "../interfaces/Fixture";

interface accordionInterface {
  data: Array<Competition>;
  onChange: React.FormEventHandler<HTMLDivElement>;
  selectedItemIds: number[];
  checkedTeamIds: number[];
  handleClickAway: (event: MouseEvent | TouchEvent) => void;
  handleClick: MouseEventHandler<HTMLDivElement>;
  error?: string | null;
}

interface DayofWeekHeader {
  index: number;
  competition: Competition;
  date: {
    time: string;
    dayOfWeek: string;
  };
}

const AccordionDrop = ({
  data,
  selectedItemIds,
  onChange,
  checkedTeamIds,
  handleClickAway,
  handleClick,
  error,
}: accordionInterface): ReactElement => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const convertTimeFromUtc = (utc: string) => {
    const customParseFormat = require("dayjs/plugin/customParseFormat");
    dayjs.extend(customParseFormat);
    const time = dayjs(utc).format("HH:mm - DD/MM/YY");
    const dayOfWeek = dayjs(utc).format("dddd");
    return { time, dayOfWeek };
  };

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const DayOfWeekHeader = ({
    index,
    competition,
    date,
  }: DayofWeekHeader): ReactElement => {
    if (
      index === 0 ||
      convertTimeFromUtc(competition.fixtures[index - 1].kickOffDateTimeUtc)
        .dayOfWeek !== date.dayOfWeek
    ) {
      return (
        <Box
          sx={{
            padding: "0px 16px",
            textAlign: "left",
            backgroundColor: Colors.Base100,
          }}
        >
          <Typography>{date.dayOfWeek}</Typography>
        </Box>
      );
    }
    return <></>;
  };

  return (
    <Box sx={{ padding: "16px 0px 16px 0px" }}>
      <FormGroup onChange={onChange}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box>
            {data.map((competition: Competition) => {
              return (
                <Accordion
                  key={competition.name}
                  sx={{ pd: 0 }}
                  expanded={expanded === competition.name}
                  onChange={handleChange(competition.name)}
                  onClick={handleClick}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      {competition.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ padding: 0, backgroundColor: Colors.Base300 }}
                  >
                    <Box sx={{ maxHeight: "390px", overflow: "scroll" }}>
                      {competition.fixtures.map(
                        (fixture: Fixture, index: number): ReactElement => {
                          const date = convertTimeFromUtc(
                            fixture.kickOffDateTimeUtc
                          );
                          return (
                            <Box key={fixture.fixtureId}>
                              <DayOfWeekHeader
                                index={index}
                                competition={competition}
                                date={date}
                              />
                              <FormControlLabel
                                sx={{ padding: "0px 32px" }}
                                value={fixture.fixtureId}
                                control={
                                  <Checkbox
                                    disabled={
                                      (checkedTeamIds.includes(
                                        fixture.homeClubId
                                      ) ||
                                        checkedTeamIds.includes(
                                          fixture.awayClubId
                                        )) &&
                                      !selectedItemIds.includes(
                                        fixture.fixtureId
                                      )
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={fixture.headerText}
                              />
                              <Typography sx={{ padding: "0px 32px" }}>
                                {date.time}
                              </Typography>
                              <br />
                            </Box>
                          );
                        }
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
            {error && (
              <FormHelperText sx={{ color: Colors.Error300 }}>
                {error}
              </FormHelperText>
            )}
          </Box>
        </ClickAwayListener>
      </FormGroup>
    </Box>
  );
};

export default AccordionDrop;
