import React from "react";
import MUITable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

interface TableProps<T> {
  titles: string[];
  data: T[];
}

const Table = <T extends object>({ data, titles }: TableProps<T>) => {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
      <MUITable
        stickyHeader
        sx={{ minWidth: 650, maxHeight: 400 }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            {titles.map((title: string) => (
              <TableCell align="right" key={title}>
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: { [key: string]: any }) => (
            <TableRow
              key={JSON.stringify(row)}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {Object.keys(row).map((key) => (
                <TableCell key={key} align="right">
                  {row[key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
};

export default Table;
