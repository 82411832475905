const Colors = {
  Black: "#000000",
  Primary050: "#e6eaee",
  Primary100: "#bfc9d7",
  Primary200: "#97a7bb",
  Primary300: "#71859f",
  Primary400: "#526b8d",
  Primary500: "#32537d",
  Primary600: "#2c4c75",
  Primary700: "#24436a",
  Primary800: "#1e395d",
  Primary900: "#182944",
  PrimaryVariants050: "#43516f",
  PrimaryVariants100: "#182944",
  PrimaryVariants200: "#00001e",
  Secondary050: "#d7fdf0",
  Secondary100: "#98f8da",
  Secondary200: "#00f2c1",
  Secondary300: "#00e9aa",
  Secondary400: "#00df98",
  Secondary500: "#00d585",
  Secondary600: "#00c578",
  Secondary700: "#00b169",
  Secondary800: "#009f5b",
  Secondary900: "#007d43",
  SecondaryVariant050: "#6afff4",
  SecondaryVariant100: "#00f2c1",
  SecondaryVariant200: "#00be91",
  Base050: "#ffffff",
  Base100: "#fafafa",
  Base200: "#f5f5f5",
  Base300: "#f0f0f0",
  Base400: "#dedede",
  Base500: "#c2c2c2",
  Base600: "#979797",
  Base700: "#818181",
  Base800: "#606060",
  Base900: "#3c3c3c",
  Base: "#282828",
  Success050: "#d1eedd",
  Success100: "#a3ddbc",
  Success200: "#5dc389",
  Success300: "#18a957",
  Success400: "#11763d",
  Success500: "#0a4423",
  Success600: "#052211",
  Warning050: "#fff4e2",
  Warning100: "#ffeccd",
  Warning200: "#ffe833",
  Warning300: "#ffd300",
  Warning400: "#fc9d00",
  Warning500: "#9c6600",
  Warning600: "#5e400a",
  Error050: "#f9d0d9",
  Error100: "#f2a2b3",
  Error200: "#e95c7b",
  Error300: "#df1642",
  Error400: "#9c0f2e",
  Error500: "#59091a",
  Error600: "#2d040d",
  LeaderboardGreenWithOpacity: (position: number): string => {
    return `rgba(0, 242, 193, ${1 / position})`;
  },
};

export default Colors;
