import type { ReactElement } from "react";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { LoginContext } from "../context/LoginContext";

const PrivateRoute = ({ element }: { element: ReactElement }) => {
  const { isLoggedIn } = useContext(LoginContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn === false) {
      navigate("/login");
    }
  });

  return element;
};

export default PrivateRoute;
