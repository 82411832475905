import dayjs from "dayjs";
import React from "react";
import Chart from "react-google-charts";
import { FeaturedBanners } from "../interfaces/Carousel";

interface GanntChart {
  bannerData: FeaturedBanners[];
}

const GanttChart = ({ bannerData }: GanntChart) => {
  const getProgress = (startDate: Date, endDate: Date) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const today = Date.now();

    const total = +end - +start;
    const progress = +today - +start;
    let percentage = Math.round((progress / total) * 100);

    if (percentage < 0) {
      percentage = 0;
    } else if (percentage > 100) {
      percentage = 100;
    }
    return percentage;
  };

  const columns = [
    { type: "string", label: "Name" },
    { type: "string", label: "Name" },
    { type: "string", label: "Name" },
    { type: "date", label: "Show From Date Time Utc" },
    { type: "date", label: "Show Until Date Time Utc" },
    { type: "number", label: "Duration" },
    { type: "number", label: "Order" },
    { type: "string", label: "Type" },
  ];

  let rows = [];

  bannerData.forEach((banner) => {
    const showFromDateTimeUtc = dayjs(banner.showFromDateTimeUtc).toDate();
    const showUntilDateTimeUtc = dayjs(banner.showUntilDateTimeUtc).toDate();

    let progress = getProgress(showFromDateTimeUtc, showUntilDateTimeUtc);

    let row = [
      banner.name,
      banner.name,
      banner.name,
      new Date(showFromDateTimeUtc),
      new Date(showUntilDateTimeUtc),
      progress,
      progress,
      null,
    ];

    rows.push(row);
  });

  const data = [columns, ...rows];

  const options = {
    height: 400,
    gantt: {
      trackHeight: 30,
    },
  };

  return (
    <Chart
      chartType="Gantt"
      width="100%"
      height="50%"
      data={data}
      options={options}
    />
  );
};

export default GanttChart;
