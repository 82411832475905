export const currencyValidation = /^((0(?!\.00)|[1-9]\d{0,6})\.\d{2}|[0-9]+)$/;
export const numberValidation = /^[0-9]+$/;
export const letterValidation = /^[A-Za-z]+$/;
export const emailValidation = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const nonZeroCurrencyValidation = /^\s*(?=.*[1-9])\d*(?:\.\d{2})?\s*$/;
export const potCodeValidation = /^.{1,25}$/;
export const payoutStructureValidation =
  /^(?!.*--)(?!.*\.\d*\.)[0-9]+(?:\.[0-9]+)?(?:-[0-9]+(?:\.[0-9]+)?)*$/;
export const bannerFileNameValidation = /^[A-Za-z0-9-]*$/;
export const nameValidation =
  /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\ \-\'\'\'\']+$/;
// /^(\d+-?)+\d+$/
