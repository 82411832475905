import {
  Box,
  FormControlLabel,
  FormGroup,
  Checkbox as MuiCheckbox,
  FormLabel,
} from "@mui/material";
import type { ChangeEvent, ReactElement } from "react";
import React from "react";

interface checkboxInterface {
  checkboxLabels: Array<string>;
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  allDefaultChecked: boolean;
}

const Checkbox = ({
  checkboxLabels,
  label,
  onChange,
  allDefaultChecked,
}: checkboxInterface): ReactElement => {
  return (
    <Box sx={{ padding: "16px 0px 16px 0px" }}>
      <FormLabel id="demo-radio-buttons-group" sx={{ fontSize: "12px" }}>
        {label}
      </FormLabel>
      <FormGroup>
        {checkboxLabels.map((checkboxLabel: string) => (
          <FormControlLabel
            key={checkboxLabel}
            control={
              <MuiCheckbox
                onChange={onChange}
                defaultChecked={allDefaultChecked}
                value={checkboxLabel}
              />
            }
            label={checkboxLabel}
          />
        ))}
      </FormGroup>
    </Box>
  );
};

export default Checkbox;
