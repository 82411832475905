import type { ReactElement } from "react";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import { metricURLs } from "../api/url";
import type { LoginContextInterface } from "../interfaces/Login";
import type { UserMetrics } from "../interfaces/UserMetrics";
import type { BettingMetrics } from "../interfaces/BettingMetrics";
import type { UsageStakesMetrics } from "../interfaces/UsageStakesMetrics";
import type { UsageMetrics } from "../interfaces/UsageMetrics";
import Table from "../components/Table";

const MetricsScreen = (): ReactElement => {
  const [userMetrics, setUserMetrics] = useState<UserMetrics[]>([]);
  const [usageMetrics, setUsageMetrics] = useState<UsageMetrics[]>([]);
  const [usageStakesMetrics, setUsageStakesMetrics] = useState<
    UsageStakesMetrics[]
  >([]);
  const [bettingMetrics, setBettingMetrics] = useState<BettingMetrics[]>([]);

  const getUserMetrics = async (): Promise<void> => {
    try {
      const url = metricURLs.getUserMetrics;
      const token = localStorage.getItem("loginInfo");

      if (token === null) {
        return;
      }

      const storedAccessToken: LoginContextInterface = JSON.parse(token);

      if (storedAccessToken === null) {
        return;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${storedAccessToken.accessToken}`,
        },
      });

      const userMetricsResponse = await response.json();
      const manipulateUserMetricsResponse = userMetricsResponse.map(
        (userMetric: UserMetrics) => {
          return {
            ...userMetric,
            TotalStakes: userMetric.TotalStakes ?? 0,
            Month: dayjs(userMetric.Month).format("MMM, YYYY"),
          };
        }
      );
      setUserMetrics(manipulateUserMetricsResponse);
    } catch (err) {
      //log this fucking error
    }
  };

  const getUsageMetrics = async (): Promise<void> => {
    try {
      const url = metricURLs.getUsageMetrics;
      const token = localStorage.getItem("loginInfo");

      if (token === null) {
        return;
      }

      const storedAccessToken: LoginContextInterface = JSON.parse(token);

      if (storedAccessToken === null) {
        return;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${storedAccessToken.accessToken}`,
        },
      });

      const usageMetricsResponse = await response.json();
      const manipulateResponse = usageMetricsResponse.map(
        (usageMetric: UsageMetrics) => {
          return {
            ...usageMetric,
            Month: dayjs(usageMetric.Month).format("MMM, YYYY"),
          };
        }
      );
      setUsageMetrics(manipulateResponse);
    } catch (err) {
      //log this fucking error
    }
  };

  const getUsageStakesMetrics = async (): Promise<void> => {
    try {
      const url = metricURLs.getUsageStakesMetrics;
      const token = localStorage.getItem("loginInfo");

      if (token === null) {
        return;
      }

      const storedAccessToken: LoginContextInterface = JSON.parse(token);

      if (storedAccessToken === null) {
        return;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${storedAccessToken.accessToken}`,
        },
      });

      const usageStakesMetricsResponse = await response.json();
      const manipulateUsageStakes = usageStakesMetricsResponse.map(
        (usageStakeMetric: UsageStakesMetrics) => {
          return {
            ...usageStakeMetric,
            Month: dayjs(usageStakeMetric.Month).format("MMM, YYYY"),
            StakeToJoinAllPots: usageStakeMetric.StakeToJoinAllPots ?? 0,
          };
        }
      );
      setUsageStakesMetrics(manipulateUsageStakes);
    } catch (err) {
      //log this fucking error
    }
  };

  const getBettingMetrics = async (): Promise<void> => {
    try {
      const url = metricURLs.getBettingMetrics;
      const token = localStorage.getItem("loginInfo");

      if (token === null) {
        return;
      }

      const storedAccessToken: LoginContextInterface = JSON.parse(token);

      if (storedAccessToken === null) {
        return;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${storedAccessToken.accessToken}`,
        },
      });

      const bettingMetricsResponse = await response.json();
      const manipulateBettingMetrics = bettingMetricsResponse.map(
        (bettingMetric: BettingMetrics) => {
          const {
            MonthStart,
            TotalEntries,
            TotalPaidEntries,
            TotalStakedCash,
            TotalStakedFreeBets,
            TotalPaidOutCash,
            TotalPaidOutFreeBets,
            TotalDeposited,
            TotalWithdraw,
          } = bettingMetric;
          return {
            MonthStart: dayjs(MonthStart).format("MMM, YYYY"),
            TotalEntries,
            TotalPaidEntries,
            TotalStakedCash: TotalStakedCash ?? 0,
            TotalStakedFreeBets,
            TotalPaidOutCash,
            TotalPaidOutFreeBets,
            TotalDepostied: TotalDeposited ?? 0,
            TotalWithdraw: TotalWithdraw ?? 0,
          };
        }
      );
      setBettingMetrics(manipulateBettingMetrics);
    } catch (err) {
      //log this fucking error
    }
  };

  useEffect(() => {
    getUserMetrics();
    getUsageMetrics();
    getUsageStakesMetrics();
    getBettingMetrics();
  }, []);

  return (
    <div>
      <Table<UserMetrics>
        data={userMetrics}
        titles={[
          "User Metrics",
          "New Users",
          "Verified",
          "Played free pot",
          "Played paid pot",
          "Finished in Paid Place",
          "Deposited Money",
          "Withdrew Money",
          "Total Stakes",
        ]}
      />

      <div style={{ height: 30, width: 400 }} />

      <Table
        data={usageMetrics}
        titles={[
          "Usage Metrics",
          "Unique Bettors",
          "Excluding Free",
          "Three Plus",
          "Five Plus",
          "Ten Plus",
          "Fifteen Plus",
          "Twenty Plus",
          "Twenty Five Plus",
          "Thirty Plus",
          "Thirty Five Plus",
        ]}
      />

      <div style={{ height: 30, width: 400 }} />

      <Table
        data={usageStakesMetrics}
        titles={[
          "Usage Staked Metrics",
          "Unique Bettors",
          "Exc. Free",
          "Stake Join All Pots",
          "Staked 2+",
          "Staked 3+",
          "Staked 5+",
          "Staked 10+",
          "Staked 20+",
          "Staked 30+",
          "Staked 40+",
          "Staked 50+",
          "Staked 75+",
          "Staked 100+",
          "Staked 150+",
          "Staked 200+",
        ]}
      />

      <div style={{ height: 30, width: 400 }} />

      <Table
        data={bettingMetrics}
        titles={[
          "Usage Staked Metrics",
          "Total Entries",
          "Total Paid Entries",
          "Total Staked Cash",
          "Total Staked Free Bets",
          "Total Paid Out Cash",
          "Total Paid Out Free Bets",
          "Total Deposited",
          "Total Withdraw",
        ]}
      />

      <div style={{ height: 30, width: 400 }} />
    </div>
  );
};

export default MetricsScreen;
