import auth0 from "auth0-js";
import { access } from "fs";
import React, { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { LoginContext } from "../context/LoginContext";
import { UserContext } from "../context/UserContext";
import type { LoginInfoInterface } from "../interfaces/Login";
import type { UserInfoInterface } from "../interfaces/User";

const CaptureScreen = () => {
  const loginContext = useContext<LoginInfoInterface>(LoginContext);
  const userContext = useContext<UserInfoInterface>(UserContext);
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  const currentUrl = window.location.origin;

  const webAuth = new auth0.WebAuth({
    clientID: "uEiollaVDURaBp24yWo023fAW24L4zAJ",
    domain: "betmate-cms.uk.auth0.com",
    redirectUri: `${currentUrl}`,
    responseType: "token",
    audience: "https://betmate-cms.uk.auth0.com/api/v2/",
  });

  useEffect(() => {
    if (dataFetchedRef.current) {
      return;
    }
    dataFetchedRef.current = true;
    onSetInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loginContext.sessionTime !== null) {
      navigate("/");
    }
  });

  localStorage.removeItem("sessionTime");
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem("loginInfo");
  localStorage.removeItem("userInfo");

  const onSetInfo = () => {
    webAuth.parseHash({ hash: window.location.hash }, (err, authResult) => {
      if (err) {
        console.log(err);
        return
      }
      if (authResult?.accessToken !== undefined) {
        const { accessToken } = authResult;
        const { expiresIn } = authResult;
        webAuth.client.userInfo(authResult.accessToken, function (error, user) {
          if (error) {
             console.log(err);
             return
          }
          if (user) {
            const { name } = user;
            const { picture } = user;
            const updatedAt = user.updated_at;

            loginContext.setLoginInfo({ accessToken, expiresIn });
            userContext.setUserInfo({ name, picture, updatedAt });
            loginContext.setIsLoggedIn(true);

            if (expiresIn !== undefined) {
              const expiresInSeconds = expiresIn * 1000;
              loginContext.setSessionTime(Date.now() + expiresInSeconds);
            }
          }
        });
      }
    });
  };

  return <></>;
};

export default CaptureScreen;
