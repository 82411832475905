import type { ReactElement } from "react";
import React, { createContext, useState } from "react";

import type {
  LoginContextInterface,
  LoginInfoInterface,
} from "../interfaces/Login";

const LoginContext = createContext<LoginInfoInterface>({
  loginInfo: { accessToken: "", expiresIn: 0 },
  setLoginInfo: () => {},
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  sessionTime: null,
  setSessionTime: () => {},
});

const LoginProvider = ({ children }: { children: ReactElement }) => {
  let isUserLoggedIn = false;
  let localSessionTime = null;

  const getLocalSessionTime: string | null =
    localStorage.getItem("sessionTime");

  if (getLocalSessionTime !== null && getLocalSessionTime !== undefined) {
    localSessionTime = JSON.parse(getLocalSessionTime);
    const currentTime = Date.now();

    const sessionFinished: boolean =
      localSessionTime !== undefined &&
      localSessionTime != null &&
      currentTime >= localSessionTime;

    if (sessionFinished) {
      isUserLoggedIn = false;
    } else {
      isUserLoggedIn = true;
    }
  }

  const [loginInfo, setLoginInfo] = useState<
    LoginContextInterface | undefined | null
  >();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(isUserLoggedIn);
  const [sessionTime, setSessionTime] = useState<number | undefined | null>(
    localSessionTime
  );

  return (
    <LoginContext.Provider
      value={{
        loginInfo,
        setLoginInfo,
        setIsLoggedIn,
        isLoggedIn,
        sessionTime,
        setSessionTime,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export { LoginProvider, LoginContext };
