import type { ReactElement } from "react";
import React, { createContext, useState } from "react";

import type {
  UserContextInterface,
  UserInfoInterface,
} from "../interfaces/User";

const UserContext = createContext<UserInfoInterface>({
  userInfo: { name: "", picture: "", updatedAt: "" },
  setUserInfo: () => {},
});

const UserProvider = ({ children }: { children: ReactElement }) => {
  const [userInfo, setUserInfo] = useState<
    UserContextInterface | undefined | null
  >();

  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUserInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
