import {
  Box,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  TextField as MUITextField,
} from "@mui/material";
import type {
  ChangeEventHandler,
  MouseEventHandler,
  ReactElement,
} from "react";
import React from "react";

interface textInterface {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  label: string;
  inputAdornment?: string;
  handleClick?: MouseEventHandler<HTMLDivElement>;
  error: string | null;
  required?: boolean;
  handleClickAway?: (event: MouseEvent | TouchEvent) => void | undefined;
  inputAdornmentEnd?: boolean;
}

const TextField = ({
  value,
  onChange,
  label,
  inputAdornment,
  handleClick,
  error,
  required,
  handleClickAway,
  inputAdornmentEnd,
}: textInterface): ReactElement => {
  const textField = () => {
    if (inputAdornmentEnd) {
      return (
        <FormControl
          sx={{ padding: "16px 0px 16px 0px", width: "100%" }}
          variant="outlined"
        >
          <FormHelperText id="outlined-weight-helper-text">
            {label}
          </FormHelperText>
          <OutlinedInput
            id="outlined-adornment-weight"
            onClick={handleClick ? handleClick : undefined}
            required={required !== undefined ? false : true}
            endAdornment={
              <InputAdornment position="end">{inputAdornment}</InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": label,
            }}
            value={value}
            onChange={onChange}
            error={!!error}
            sx={{ width: "100%" }}
          />
          {error && (
            <FormHelperText error id="accountId-error">
              {error}
            </FormHelperText>
          )}
        </FormControl>
      );
    } else {
      return (
        <Box sx={{ padding: "16px 0px 16px 0px" }}>
          <MUITextField
            onClick={handleClick ? handleClick : undefined}
            required={required !== undefined ? false : true}
            id="outlined-required"
            label={label}
            type={"text"}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error}
            sx={{ width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position={inputAdornmentEnd ? "end" : "start"}>
                  {inputAdornment}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      );
    }
  };

  return handleClickAway ? (
    <ClickAwayListener onClickAway={handleClickAway}>
      {textField()}
    </ClickAwayListener>
  ) : (
    textField()
  );
};

export default TextField;
