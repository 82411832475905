import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import type { SelectChangeEvent } from "@mui/material/Select";
import Select from "@mui/material/Select";
import type { MouseEventHandler, ReactElement } from "react";
import React from "react";
import { ClickAwayListener, FormHelperText } from "@mui/material";

import type { menuItem } from "../interfaces/MenuItem";
import Colors from "../config/Colors";

interface dropdownInterface {
  handleClick: MouseEventHandler<HTMLDivElement>;
  handleClickAway?: (event: MouseEvent | TouchEvent) => void;
  dropdownValue: string | undefined;
  onChange: (event: SelectChangeEvent<string>) => void;
  menuItems: Array<menuItem>;
  error?: string | null;
  label: string;
}

const DropDownField = ({
  error,
  handleClick,
  handleClickAway,
  dropdownValue,
  onChange,
  menuItems,
  label,
}: dropdownInterface): ReactElement => {
  const dropdownField = (
    <Box sx={{ padding: "16px 0px 16px 0px" }}>
      <FormControl fullWidth>
        <InputLabel id="select">{label}</InputLabel>
        <Select
          onClick={handleClick}
          required
          value={dropdownValue ? dropdownValue : ""}
          error={!!error}
          label={label}
          onChange={onChange}
        >
          {menuItems.map((menuItem: menuItem) => {
            return (
              <MenuItem key={menuItem.id} value={menuItem.id}>
                {menuItem.label}
              </MenuItem>
            );
          })}
        </Select>
        {error && (
          <FormHelperText sx={{ color: Colors.Error300 }}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );

  return handleClickAway ? (
    <ClickAwayListener onClickAway={handleClickAway}>
      {dropdownField}
    </ClickAwayListener>
  ) : (
    dropdownField
  );
};

export default DropDownField;
