import { userDataUrls } from "../../api/url";
import { LoginContextInterface } from "../../interfaces/Login";
import { ApiResponse } from "../../interfaces/PotBuilderForm";
import { UsernamePost } from "../../UserInfo/interfaces/UserData";

const getUserPots = async (
    data: UsernamePost
  ): Promise<ApiResponse | undefined> => {
    const url = userDataUrls.getUserPots;
    try {
      const token = localStorage.getItem("loginInfo");
      if (token !== null) {
        const storedAccessToken: LoginContextInterface = JSON.parse(token);
        if (storedAccessToken.accessToken != null) {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${storedAccessToken.accessToken}`,
            },
            body: JSON.stringify({ data }),
          });
  
          try {
            const userPotsResponse: ApiResponse = await response.json();
            return userPotsResponse;
          } catch (err) {
            console.log(err);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  
  export default getUserPots;