import type {
  ChangeEvent,
  Dispatch,
  ReactElement,
  SetStateAction,
  SyntheticEvent,
} from "react";
import React, { useContext, useEffect, useState } from "react";
import type { AlertColor, SelectChangeEvent } from "@mui/material";
import { Alert, Button, Grid, LinearProgress, Snackbar } from "@mui/material";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import useAsyncEffect from "use-async-effect";
import LoadingButton from "@mui/lab/LoadingButton";
import utc from "dayjs/plugin/utc";

import TextField from "../components/TextField";
import DropDownField from "../components/DropDownField";
import RadioField from "../components/Radio";
import TimeField from "../components/TimeField";
import AccordionDrop from "../components/Accordion";
import {
  PotBuilderFormHeaders,
  PotBuilderFormOptions,
  FormErrors,
  PotCreatorForm,
  PotBuilderCappedOptions,
  AlertTextPot,
} from "../config/Text";
import "../config/fonts.css";
import type { Fixture, Competition } from "../interfaces/Fixture";
import type { menuItem } from "../interfaces/MenuItem";
import Colors from "../config/Colors";
import {
  currencyValidation,
  nonZeroCurrencyValidation,
  numberValidation,
  payoutStructureValidation,
  potCodeValidation,
} from "../config/Validation";
import getFixtures from "../api/PotBuilder/getFixtures";
import type { PotBuilderFormData } from "../interfaces/PotBuilderForm";
import postFormData from "../api/PotBuilder/postFormData";
import {
  hardcodedCrests,
  hardcodedPayoutRatio,
} from "../config/hardcoded data/hardcodedCrests";
import { LoginContext } from "../context/LoginContext";
import type { LoginInfoInterface } from "../interfaces/Login";
import Checkbox from "../components/Checkbox";

export const handleTextChange = (
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  regexValidation: RegExp | null,
  setState: Dispatch<string>,
  setError?: Dispatch<string>,
  capitalised?: boolean
) => {
  const newText = event.target.value;
  const capitalisedText = newText.toUpperCase();

  if (regexValidation !== null && setError) {
    if (newText.match(regexValidation) === null) {
      setError("Invalid Input");
    } else {
      setError("");
    }
  }
  setState(capitalised ? capitalisedText : event?.target.value);
};

export const handleClick = (setClicked: Dispatch<boolean>) => {
  setClicked(true);
};

export const handleClickAway = (
  value: string | Dayjs | null,
  setClicked: Dispatch<boolean | null>,
  clicked: boolean | undefined | null,
  errorString: string,
  setError?: Dispatch<string>
) => {
  if (!value && clicked && setError) {
    setError(errorString);
    setClicked(null);
  } else if (value && setError) {
    setError("");
  }
};

export const handleTextClickAway = (
  setError: Dispatch<string>,
  value: string,
  setClicked: Dispatch<boolean | null>,
  clicked: boolean | undefined | null,
  regexValidation: RegExp | null,
  errorString: string
) => {
  if (!value && clicked) {
    setError(errorString);
    setClicked(null);
  } else if (
    regexValidation &&
    value &&
    value.match(regexValidation) !== null
  ) {
    setError("");
  }
};

export const handleRadioChange = (
  event: ChangeEvent<HTMLInputElement>,
  options: Array<string>,
  setBoolean: Dispatch<boolean>,
  setValue: Dispatch<SetStateAction<string>>
) => {
  const opt = event.target.value;

  setValue(opt);
  if (event !== undefined && opt === options[0]) {
    setBoolean(true);
  } else if (event !== undefined && opt === options[1]) {
    setBoolean(false);
  }
};

export const handleTimeChange = (
  event: Dayjs | null,
  setTime: Dispatch<Dayjs | null>
) => {
  const time = event;

  setTime(time);
};

export const handleFixtureChange = (
  event: React.SyntheticEvent,
  fixtureData: Competition[],
  checkedTeamId: Number[],
  fixtureIds: Number[],
  fullFixtureInfoArray: Fixture[],
  setCheckedTeamId: Dispatch<SetStateAction<Number[]>>,
  setFixtureIds: Dispatch<SetStateAction<Number[]>>,
  setFullFixtureInfoArray: Dispatch<SetStateAction<Fixture[]>>
) => {
  const opt = parseInt((event.target as HTMLInputElement).value);

  if (fixtureData) {
    fixtureData.find((competition: Competition) => {
      competition.fixtures.find((fixture: Fixture) => {
        const isAwayTeamChecked = checkedTeamId.includes(fixture.awayClubId);
        const isHomeTeamChecked = checkedTeamId.includes(fixture.homeClubId);
        const isIdSelected = fixtureIds.includes(fixture.fixtureId);
        const optionMatched = fixture.fixtureId === opt;
        const eventDefined = event !== undefined;
        const teamsUnchecked =
          isAwayTeamChecked === false && isHomeTeamChecked === false;
        const fixtureSelected = eventDefined && isIdSelected;
        const fixtureNotSelected = eventDefined && isIdSelected === false;

        if (optionMatched) {
          if (teamsUnchecked) {
            setCheckedTeamId([
              ...checkedTeamId,
              fixture.homeClubId,
              fixture.awayClubId,
            ]);
          }
          if (fixtureNotSelected) {
            setFixtureIds([...fixtureIds, fixture.fixtureId]);
            setFullFixtureInfoArray([...fullFixtureInfoArray, fixture]);
          } else if (fixtureSelected) {
            const newFixtureId = fixtureIds.filter(
              (fixtureId: number) => fixtureId !== fixture.fixtureId
            );
            const newFullFixtureArray = fullFixtureInfoArray.filter(
              (fullFixture: Fixture) => fullFixture !== fixture
            );
            setFixtureIds(newFixtureId);
            setFullFixtureInfoArray(newFullFixtureArray);
            const newCheckedTeams = checkedTeamId.filter(
              (checkedTeam: number) =>
                checkedTeam !== fixture.homeClubId &&
                checkedTeam !== fixture.awayClubId
            );
            setCheckedTeamId(newCheckedTeams);
          }
        }
      });
    });
  }
};

export const handleDropdownChange = (
  event: SelectChangeEvent,
  menuItems: Array<menuItem>,
  setValue: Dispatch<string>,
  setValuePlaceholder: Dispatch<string>,
  setError?: Dispatch<string>
) => {
  const id = Number(event.target.value);
  const selectedItem = menuItems.find((item: menuItem) => {
    return item.id === id;
  });

  if (selectedItem !== undefined) {
    setValue(selectedItem?.crest);
    setValuePlaceholder(String(selectedItem?.id));
  } else if (setError) {
    setError("Select");
  }
};

export const handleFixtureClickAway = (
  setError: Dispatch<string>,
  fixtureIds: Number[],
  fixtureClicked: boolean
) => {
  if (fixtureIds.length === 0 && fixtureClicked) {
    setError(FormErrors.fixture);
  } else if (fixtureIds.length > 0) {
    setError("");
  }
};

export const clearAll = () => {
  window.location.reload();
};

export const changeAlert = (
  alertOpen: boolean,
  severity: AlertColor | undefined,
  text: string,
  handleAlertClose: (_event: SyntheticEvent | Event, reason?: string) => void,
  autoHide?: boolean
) => {
  return (
    <Snackbar
      open={alertOpen}
      autoHideDuration={autoHide ? null : 6000}
      onClose={handleAlertClose}
    >
      <Alert
        onClose={handleAlertClose}
        severity={severity}
        sx={{ width: "100%" }}
      >
        {text}
      </Alert>
    </Snackbar>
  );
};

export const alertBanner = (
  alert: string,
  alertOpen: boolean,
  handleAlertClose: (_event: SyntheticEvent | Event, reason?: string) => void,
  progress: number,
  alertErrorMessage: string
) => {
  if (alert === "success") {
    return changeAlert(
      alertOpen,
      "success",
      AlertTextPot.success,
      handleAlertClose
    );
  } else if (alert === "progress") {
    return (
      <Snackbar open={alertOpen} onClose={handleAlertClose}>
        <Alert
          onClose={handleAlertClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {AlertTextPot.progress}
          <LinearProgress variant="determinate" value={progress} />
        </Alert>
      </Snackbar>
    );
  } else {
    return changeAlert(alertOpen, "error", alertErrorMessage, handleAlertClose);
  }
};

export const submitButton = (
  submitDisabled: boolean,
  buttonLoading: boolean,
  onSubmit: () => void
): ReactElement => {
  const buttonStyle = {
    width: "100%",
    padding: "8px",
    backgroundColor: submitDisabled ? Colors.Base400 : Colors.Primary600,
    color: Colors.Base050,
  };

  if (buttonLoading === false) {
    return (
      <Button
        disabled={submitDisabled}
        sx={buttonStyle}
        onClick={() => {
          onSubmit();
        }}
      >
        Submit
      </Button>
    );
  } else {
    return (
      <LoadingButton loading variant="outlined" sx={buttonStyle}>
        Submit
      </LoadingButton>
    );
  }
};

const Main = (): ReactElement => {
  // value hooks
  const [leftIcon, setLeftIcon] = useState<string>("");
  const [rightIcon, setRightIcon] = useState<string>("");
  const [potName, setPotName] = useState<string>("");
  const [entryFee, setEntryFee] = useState<string>("");
  const [payout, setPayout] = useState<string>("");
  const [noOfWinners, setNoOfWinners] = useState<string>("");
  const [winningsMoney, setWinningsMoney] = useState<boolean>(true);
  const [maxEntrants, setMaxEntrants] = useState<string>("");
  const [pinned, setPinned] = useState<boolean>(false);
  const [typePublic, setTypePublic] = useState<boolean>(true);
  const [potForNewUsers, setPotForNewUsers] = useState<boolean>(false);
  const [fixtureIds, setFixtureIds] = useState<number[]>([]);
  const [potCode, setPotCode] = useState<string>("");
  const [maxPlayersPerClub, setMaxPlayersPerClub] = useState<string>("");
  const [potStartTime, setPotStartTime] = useState<Dayjs | null>(null);
  const [lastFixtureStartTime, setLastFixtureStartTime] =
    useState<Dayjs | null>(null);
  const [displayTime, setDisplayTime] = useState<Dayjs | null>(null);
  const [capped120, setCapped120] = useState<boolean>(false);
  const [capped320, setCapped320] = useState<boolean>(false);
  const [capped510, setCapped510] = useState<boolean>(false);
  const [capped1010, setCapped1010] = useState<boolean>(false);
  const [capped1510, setCapped1510] = useState<boolean>(false);

  // validation hooks
  const [potNameError, setPotNameError] = useState<string>("");
  const [entryFeeError, setEntryFeeError] = useState<string>("");
  const [payoutError, setPayoutError] = useState<string>("");
  const [noOfWinnersError, setNoOfWinnersError] = useState<string>("");
  const [maxEntrantsError, setMaxEntrantsError] = useState<string>("");
  const [potCodeError, setPotCodeError] = useState<string>("");
  const [maxPlayersPerClubError, setMaxPlayersPerClubError] =
    useState<string>("");
  const [fixtureError, setFixtureError] = useState<string>("");
  const [potStartTimeError, setPotStartTimeError] = useState<string>("");
  const [displayTimeError, setDisplayTimeError] = useState<string>("");
  const [payoutStructureError, setPayoutStructureError] = useState<string>("");

  //text hooks
  const [potNameClicked, setPotNameClicked] = useState<boolean | null>();
  const [entryFeeClicked, setEntryFeeClicked] = useState<boolean | null>();
  const [payoutClicked, setPayoutClicked] = useState<boolean | null>();
  const [noOfWinnersClicked, setNoOfWinnersClicked] = useState<
    boolean | null
  >();
  const [potCodeClicked, setPotCodeClicked] = useState<boolean | null>();
  const [maxPlayersPerClubClicked, setMaxPlayersPerClubClicked] = useState<
    boolean | null
  >();
  const [payoutStructureClicked, setPayoutStructureClicked] = useState<
    boolean | null
  >();

  //fixture hooks
  const [checkedTeamId, setCheckedTeamId] = useState<number[]>([]);
  const [fixtureClicked, setFixtureClicked] = useState<boolean | null>();
  const [fullFixtureInfoArray, setFullFixtureInfoArray] = useState<Fixture[]>(
    []
  );
  const [sortedFixtures, setSortedFixtures] = useState<Fixture[]>([]);

  //dropdown hooks
  const [dropdownValueLeft, setDropdownValueLeft] = useState<string>("");
  const [dropdownValueRight, setDropdownValueRight] = useState<string>("");
  const [dropdownValueWinners, setDropdownValueWinners] = useState<string>("");
  const [dropdownLeftClicked, setDropdownLeftClicked] = useState<
    boolean | null
  >();
  const [dropdownRightClicked, setDropdownRightClicked] = useState<
    boolean | null
  >();

  //radio hooks
  const [winningsMoneyRadio, setWinningsMoneyRadio] = useState("Money");
  const [pinnedRadio, setPinnedRadio] = useState("False");
  const [typePublicRadio, setTypePublicRadio] = useState("Public");
  const [potForNewUsersRadio, setPotForNewUsersRadio] = useState("False");

  //time hooks
  const [potStartTimeClicked, setPotStartTimeClicked] = useState<
    boolean | null
  >();

  //field filled hooks
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertErrorMessage, setAlertErrorMessage] = useState<string>("");

  //data hooks
  const [fixtureData, setFixtureData] = useState<Array<Competition>>([
    { name: "", fixtures: [] },
  ]);
  const [crestData, setCrestData] = useState<menuItem[]>([]);
  const [numberOfWinnersData, setNumberOfWinnersData] = useState<menuItem[]>(
    []
  );
  const [progress, setProgress] = useState<number>();

  //context
  const { loginInfo } = useContext<LoginInfoInterface>(LoginContext);

  dayjs.extend(utc);

  useEffect(() => {
    if (fullFixtureInfoArray.length === 0) {
      return;
    }
    if (fullFixtureInfoArray.length > 0) {
      const sortedArray: Fixture[] = fullFixtureInfoArray.sort((a, b) =>
        dayjs(a.kickOffDateTimeUtc).isAfter(dayjs(b.kickOffDateTimeUtc))
          ? 1
          : -1
      );
      setSortedFixtures(sortedArray);
      if (sortedArray.length - 1) {
        const lastStartTime = dayjs.utc(
          sortedArray[sortedArray.length - 1].kickOffDateTimeUtc
        );

        setLastFixtureStartTime(lastStartTime);
      } else {
        const lastStartTime = dayjs.utc(sortedArray[0].kickOffDateTimeUtc);
        setLastFixtureStartTime(lastStartTime);
      }
    }
  }, [fullFixtureInfoArray]);

  useEffect(() => {
    if (fixtureIds.length === 1) {
      setMaxPlayersPerClub("6");
    } else if (fixtureIds.length === 2) {
      setMaxPlayersPerClub("4");
    } else if (fixtureIds.length === 3) {
      setMaxPlayersPerClub("3");
    } else if (fixtureIds.length >= 4) {
      setMaxPlayersPerClub("2");
    }

    if (sortedFixtures.length > 0) {
      const [fixture] = sortedFixtures;
      if (fixture) {
        const kickOff = dayjs(fixture.kickOffDateTimeUtc);
        const autoPotCode = `FF-${fixture.homeClubShortName}-${fixture.awayClubShortName}`;
        const leftIconValue = String(fixture.homeClubId);
        const rightIconValue = String(fixture.awayClubId);

        setPotStartTime(kickOff);

        if (sortedFixtures.length === 1) {
          setPotCode(autoPotCode);
          setDropdownValueLeft(leftIconValue);
          setLeftIcon(`:crest-${leftIconValue}:`);
          setDropdownValueRight(rightIconValue);
          setRightIcon(`:crest-${rightIconValue}:`);
          setPotName(`${fixture.homeClubName} vs ${fixture.awayClubName}`);
        } else {
          setPotCode("");
          setDropdownValueLeft("999");
          setDropdownValueRight("999");
          setPotName("");
          setLeftIcon("");
          setRightIcon("");
        }
      }
    }
  }, [fixtureIds.length, sortedFixtures]);

  useEffect(() => {
    if (noOfWinners === hardcodedPayoutRatio[0].crest) {
      setPotForNewUsersRadio("True");
      setPotForNewUsers(true);
      setMaxEntrants("40");
    } else {
      setPotForNewUsersRadio("False");
    }
  }, [noOfWinners]);

  useEffect(() => {
    if (fixtureIds.length === 0 && fixtureClicked === true) {
      setFixtureError(FormErrors.fixture);
    } else {
      setFixtureError("");
    }
  }, [fixtureClicked, fixtureIds]);

  useEffect(() => {
    if (potName.length === 0 && potNameClicked === true) {
      setPotNameError(FormErrors.text);
    } else {
      setPotNameError("");
    }
  }, [potName, potNameClicked]);

  useEffect(() => {
    const entryFeeNumber = Number(entryFee);
    const isEntryFeeHigher = checkIfEntryFeeHigher();

    if (
      (entryFee.length !== 0 &&
        (entryFeeNumber < 1 || entryFeeNumber > 100) &&
        entryFeeNumber !== 0) ||
      (entryFeeClicked && entryFee.length === 0)
    ) {
      setEntryFeeError(FormErrors.entryFee);
    } else if (isEntryFeeHigher === true) {
      setEntryFeeError(FormErrors.entryFeeHigher);
    } else if (
      ((entryFee.match(currencyValidation) !== null &&
        entryFeeNumber >= 1 &&
        entryFeeNumber <= 100 &&
        entryFee.length !== 0) ||
        entryFeeNumber === 0) &&
      isEntryFeeHigher === false &&
      entryFee.length !== 0
    ) {
      setEntryFeeError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryFee, payout]);

  useEffect(() => {
    const payoutNumber = Number(payout);
    const isEntryFeeHigher = checkIfEntryFeeHigher();

    if (payout.length !== 0 && payoutNumber > 5000) {
      setPayoutError(FormErrors.payout);
    } else if (isEntryFeeHigher === true) {
      setEntryFeeError(FormErrors.entryFeeHigher);
    } else if (
      payout.match(currencyValidation) !== null &&
      payoutNumber < 5000 &&
      maxEntrants.length != 0 &&
      isEntryFeeHigher === false
    ) {
      setPayoutError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payout]);

  useEffect(() => {
    const maxEntrantsNumber = Number(maxEntrants);

    if (maxEntrants.length != 0 && maxEntrantsNumber < 2) {
      setMaxEntrantsError(FormErrors.text);
    } else if (
      maxEntrants.match(numberValidation) !== null ||
      maxEntrants.length === 0
    ) {
      setMaxEntrantsError("");
    }
  }, [maxEntrants]);

  useEffect(() => {
    if (
      (potName.length === 0 || potName.length > 70) &&
      potNameClicked === true
    ) {
      setPotNameError(FormErrors.text);
    } else {
      setPotNameError("");
    }
  }, [potName, potNameClicked]);

  useEffect(() => {
    let payoutSum: number | null = null;
    let descendingOrder;

    if (
      noOfWinners &&
      noOfWinners.length > 0 &&
      noOfWinners.slice(-1) !== "-" &&
      noOfWinners.slice(-1) !== "." &&
      noOfWinners.match(payoutStructureValidation)
    ) {
      descendingOrder = checkDescendingOrder(noOfWinners);
      payoutSum = 0;

      const numbers = noOfWinners.split("-").map(Number);
      if (numbers.length > 0) {
        for (let i = 0; i < numbers.length; i++) {
          payoutSum = numbers[i] * 1000 + payoutSum;
        }
      }
    }

    if (noOfWinners.length === 0 && noOfWinnersClicked === true) {
      setNoOfWinnersError(FormErrors.icon);
    } else if (noOfWinners.slice(-1) !== "-") {
      setNoOfWinnersError("");
    }

    if (dropdownValueWinners.length === 0 && noOfWinnersClicked) {
      setNoOfWinnersError(FormErrors.icon);
    }

    if (
      (payoutSum === null ||
        payoutSum !== 100000 ||
        noOfWinners.length === 0) &&
      payoutStructureClicked === true
    ) {
      setPayoutStructureError(FormErrors.payoutStructure);
    } else if (descendingOrder === false) {
      setPayoutStructureError(FormErrors.descendingOrder);
    } else if (
      noOfWinners.slice(-1) !== "-" &&
      descendingOrder === true &&
      payoutSum === 100000
    ) {
      setPayoutStructureError("");
    }
  }, [
    noOfWinners,
    noOfWinnersClicked,
    payoutStructureClicked,
    dropdownValueWinners.length,
  ]);

  useEffect(() => {
    if (
      (potCode.length === 0 || potCode.length > 25) &&
      potCodeClicked === true
    ) {
      setPotCodeError(FormErrors.text);
    } else {
      setPotCodeError("");
    }
  }, [potCode, potCodeClicked]);

  useEffect(() => {
    const maxPlayersPerClubNumber = Number(maxPlayersPerClub);

    if (
      maxPlayersPerClub.length != 0 &&
      (maxPlayersPerClubNumber < 2 || maxPlayersPerClubNumber > 6)
    ) {
      setMaxPlayersPerClubError(FormErrors.maxPlayers);
    } else if (maxPlayersPerClub.match(numberValidation) !== null) {
      setMaxPlayersPerClubError("");
    }
  }, [maxPlayersPerClub]);

  useEffect(() => {
    const currentTime: Dayjs = dayjs();
    let isAfter = false;

    if (potStartTime && potStartTime.isAfter(currentTime)) {
      isAfter = true;
    }

    if (potStartTime === null && potStartTimeClicked === true) {
      setPotStartTimeError(FormErrors.time);
    } else if (potStartTime !== null && isAfter === false) {
      setPotStartTimeError(FormErrors.timeBefore);
    } else if (potStartTime !== null && isAfter === true) {
      setPotStartTimeError("");
    }
  }, [potStartTime, potStartTimeClicked]);

  useEffect(() => {
    const currentTime: Dayjs = dayjs();
    let isAfter = false;

    if (displayTime && displayTime.isAfter(currentTime)) {
      isAfter = true;
    }

    if (displayTime !== null && isAfter === false) {
      setDisplayTimeError(FormErrors.timeBefore);
    } else if (isAfter === true) {
      setDisplayTimeError("");
    }
  }, [displayTime]);

  // decided whether there are any errors and set the button enabled/disabled based on it
  useEffect(() => {
    let isError =
      fixtureError === "" &&
      potNameError === "" &&
      entryFeeError === "" &&
      maxEntrantsError === "" &&
      payoutStructureError === "" &&
      payoutError === "" &&
      noOfWinnersError === "" &&
      potStartTimeError === "" &&
      displayTimeError === "" &&
      potCodeError === "" &&
      maxPlayersPerClubError === "" &&
      fixtureIds.length !== 0 &&
      potName.length !== 0 &&
      entryFee.length !== 0 &&
      maxPlayersPerClub.length !== 0 &&
      noOfWinners.length !== 0 &&
      payout.length !== 0 &&
      potCode.length !== 0 &&
      potStartTime !== null;

    setSubmitDisabled(!isError);

    return () => {
      isError = false;
    };
  }, [
    fixtureError,
    potNameError,
    entryFeeError,
    maxEntrantsError,
    payoutStructureError,
    payoutError,
    potStartTimeError,
    noOfWinnersError,
    potCodeError,
    fixtureIds,
    potName,
    entryFee,
    noOfWinners,
    payout,
    potCode,
    potStartTime,
    displayTimeError,
    maxPlayersPerClubError,
    maxPlayersPerClub.length,
  ]);

  useAsyncEffect(async () => {
    if (crestData.length === 0) {
      await handleSetData();
    }
  });

  const checkDescendingOrder = (str: string) => {
    if (str) {
      const numbers = str.split("-").map(Number);
      if (numbers) {
        for (let i = 1; i < numbers.length; i++) {
          if (numbers[i] && numbers[i - 1] && numbers[i] > numbers[i - 1]) {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  };

  const checkIfEntryFeeHigher = () => {
    const payoutNumber = Number(payout);
    const entryFeeNumber = Number(entryFee);

    if (entryFeeNumber > payoutNumber) {
      return true;
    } else {
      return false;
    }
  };

  const handleSetData = async () => {
    if (loginInfo === null || loginInfo === undefined) {
      return;
    }
    try {
      const data: Competition[] = await getFixtures();

      if (data) {
        setFixtureData(data);

        const crestStrings: Array<string> = [];
        const crests: Array<menuItem> = [];

        data.forEach((competition: Competition) => {
          competition.fixtures.forEach((fixture: Fixture) => {
            const homeCrest = `:crest-${fixture.homeClubId}:`;
            const awayCrest = `:crest-${fixture.awayClubId}:`;

            const homeCrestsFound = crestStrings.indexOf(homeCrest) === -1;
            const awayCrestsFound = crestStrings.indexOf(awayCrest) === -1;

            if (homeCrestsFound) {
              const crestObject: menuItem = {
                id: fixture.homeClubId,
                label: fixture.homeClubName,
                crest: homeCrest,
              };
              crests.push(crestObject);
              crestStrings.push(homeCrest);
            }
            if (awayCrestsFound) {
              const crestObject: menuItem = {
                id: fixture.awayClubId,
                label: fixture.awayClubName,
                crest: awayCrest,
              };
              crests.push(crestObject);
              crestStrings.push(awayCrest);
            }
          });
        });

        const finalCrestList: Array<menuItem> = [];

        const sortedCrests = crests.sort((a, b) =>
          a.label.localeCompare(b.label)
        );

        hardcodedCrests.forEach((crest) => {
          finalCrestList.push(crest);
        });

        sortedCrests.forEach((crest) => {
          finalCrestList.push(crest);
        });

        setCrestData(finalCrestList);
        setNumberOfWinnersData(hardcodedPayoutRatio);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const opt = event.target.value;

    if (opt === PotBuilderCappedOptions.capped120) {
      setCapped120(!capped120);
    }
    if (opt === PotBuilderCappedOptions.capped320) {
      setCapped320(!capped320);
    }
    if (opt === PotBuilderCappedOptions.capped510) {
      setCapped510(!capped510);
    }
    if (opt === PotBuilderCappedOptions.capped1010) {
      setCapped1010(!capped1010);
    }
    if (opt === PotBuilderCappedOptions.capped1510) {
      setCapped1510(!capped1510);
    }
  };

  const checkForInvalidCharacter = (string: string) => {
    const validatedString = string.replace(/'/g, "''");
    return validatedString;
  };

  const onSubmit = async () => {
    setButtonLoading(true);

    const validatedPotName = checkForInvalidCharacter(potName);

    const tempDataNoCap: PotBuilderFormData = {
      leftIcon,
      rightIcon,
      potName: validatedPotName,
      entryFee,
      payout,
      noOfWinners,
      winningsMoney,
      maxEntrants,
      pinned,
      typePublic,
      potForNewUsers,
      fixtureIds,
      potCode,
      maxPlayersPerClub,
      potStartTime,
      lastFixtureStartTime,
      displayTime,
    };

    const tempData120: PotBuilderFormData = {
      leftIcon,
      rightIcon,
      potName: validatedPotName,
      entryFee: "1",
      payout: "15",
      noOfWinners: "100",
      winningsMoney,
      maxEntrants: "20",
      pinned,
      typePublic,
      potForNewUsers,
      fixtureIds,
      potCode: potCode + "-120-01",
      maxPlayersPerClub,
      potStartTime,
      lastFixtureStartTime,
      displayTime,
    };

    const tempData320: PotBuilderFormData = {
      leftIcon,
      rightIcon,
      potName: validatedPotName,
      entryFee: "3",
      payout: "45",
      noOfWinners: "70-30",
      winningsMoney,
      maxEntrants: "20",
      pinned,
      typePublic,
      potForNewUsers,
      fixtureIds,
      potCode: potCode + "-320-01",
      maxPlayersPerClub,
      potStartTime,
      lastFixtureStartTime,
      displayTime,
    };

    const tempData510: PotBuilderFormData = {
      leftIcon,
      rightIcon,
      potName: validatedPotName,
      entryFee: "5",
      payout: "40",
      noOfWinners: "100",
      winningsMoney,
      maxEntrants: "10",
      pinned,
      typePublic,
      potForNewUsers,
      fixtureIds,
      potCode: potCode + "-510-01",
      maxPlayersPerClub,
      potStartTime,
      lastFixtureStartTime,
      displayTime,
    };

    const tempData1010: PotBuilderFormData = {
      leftIcon,
      rightIcon,
      potName: validatedPotName,
      entryFee: "10",
      payout: "80",
      noOfWinners: "100",
      winningsMoney,
      maxEntrants: "10",
      pinned,
      typePublic,
      potForNewUsers,
      fixtureIds,
      potCode: potCode + "-1010-01",
      maxPlayersPerClub,
      potStartTime,
      lastFixtureStartTime,
      displayTime,
    };

    const tempData1510: PotBuilderFormData = {
      leftIcon,
      rightIcon,
      potName: validatedPotName,
      entryFee: "10",
      payout: "120",
      noOfWinners: "70-30",
      winningsMoney,
      maxEntrants: "15",
      pinned,
      typePublic,
      potForNewUsers,
      fixtureIds,
      potCode: potCode + "-1510-01",
      maxPlayersPerClub,
      potStartTime,
      lastFixtureStartTime,
      displayTime,
    };

    setButtonLoading(false);
    try {
      setAlert("progress");
      setProgress(0);
      setAlertOpen(true);
      const potId = await postFormData(tempDataNoCap);

      if (potId && potId.success === false) {
        setAlertErrorMessage(`${potId.message}`);
        setAlert("error");
      } else {
        setProgress(20);
        if (capped120 === true) {
          await postFormData(tempData120);
        }
        setProgress(40);
        if (capped320 === true) {
          await postFormData(tempData320);
        }
        setProgress(60);
        if (capped510 === true) {
          await postFormData(tempData510);
        }
        setProgress(70);
        if (capped1010 === true) {
            await postFormData(tempData1010);
        }
        setProgress(90);
        if (capped1510 === true) {
          await postFormData(tempData1510);
      }
        setProgress(100);
        setButtonLoading(false);
        setAlert("success");
        setAlertOpen(true);
      }
    } catch (err) {
      setButtonLoading(false);
      setAlertErrorMessage(AlertTextPot.error);
      setAlert("error");
      setAlertOpen(true);
    }
  };

  const handleAlertClose = (
    _event: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  return (
    <form>
      {alertBanner(
        alert,
        alertOpen,
        handleAlertClose,
        progress,
        alertErrorMessage
      )}
      <Grid
        container
        spacing={8}
        // columns={2}
        sx={{
          padding: "32px",
        }}
      >
        <Grid item xs={12} lg={6}>
          <div className={"H5BoldLeftSecondaryPressed"}>
            {PotBuilderFormHeaders.fixtures}
          </div>
          <AccordionDrop
            data={fixtureData}
            handleClick={() => {
              handleClick(setFixtureClicked);
            }}
            handleClickAway={() => {
              handleFixtureClickAway(
                setFixtureError,
                fixtureIds,
                fixtureClicked
              );
            }}
            onChange={(event) => {
              handleFixtureChange(
                event,
                fixtureData,
                checkedTeamId,
                fixtureIds,
                fullFixtureInfoArray,
                setCheckedTeamId,
                setFixtureIds,
                setFullFixtureInfoArray
              );
            }}
            error={fixtureError}
            selectedItemIds={fixtureIds}
            checkedTeamIds={checkedTeamId}
          />
          <div className={"H5BoldLeftSecondaryPressed"}>
            {PotBuilderFormHeaders.naming}
          </div>
          <DropDownField
            dropdownValue={dropdownValueLeft}
            menuItems={crestData}
            onChange={(event) => {
              handleDropdownChange(
                event,
                crestData,
                setLeftIcon,
                setDropdownValueLeft
              );
            }}
            handleClickAway={() => {
              handleClickAway(
                dropdownValueLeft,
                setDropdownLeftClicked,
                dropdownLeftClicked,
                FormErrors.icon
              );
            }}
            handleClick={() => {
              handleClick(setDropdownLeftClicked);
            }}
            label={PotCreatorForm.leftIcon}
          />
          <DropDownField
            dropdownValue={dropdownValueRight}
            menuItems={crestData}
            onChange={(event) => {
              handleDropdownChange(
                event,
                crestData,
                setRightIcon,
                setDropdownValueRight
              );
            }}
            handleClickAway={() => {
              handleClickAway(
                dropdownValueRight,
                setDropdownRightClicked,
                dropdownRightClicked,
                FormErrors.icon
              );
            }}
            handleClick={() => {
              handleClick(setDropdownRightClicked);
            }}
            label={PotCreatorForm.rightIcon}
          />
          <TextField
            value={potName}
            label={PotCreatorForm.potName}
            onChange={(event) => {
              handleTextChange(event, null, setPotName, setPotNameError, true);
            }}
            handleClick={() => {
              handleClick(setPotNameClicked);
            }}
            error={potNameError}
          />

          <div className={"H5BoldLeftSecondaryPressed"}>
            {PotBuilderFormHeaders.money}
          </div>

          <TextField
            value={payout}
            label={PotCreatorForm.payout}
            onChange={(event) => {
              handleTextChange(
                event,
                nonZeroCurrencyValidation,
                setPayout,
                setPayoutError
              );
            }}
            handleClick={() => {
              handleClick(setPayoutClicked);
            }}
            handleClickAway={() => {
              handleTextClickAway(
                setPayoutError,
                payout,
                setPayoutClicked,
                payoutClicked,
                nonZeroCurrencyValidation,
                FormErrors.text
              );
            }}
            inputAdornment={"£"}
            error={payoutError}
          />
          <TextField
            value={entryFee}
            label={PotCreatorForm.entryFee}
            onChange={(event) => {
              handleTextChange(
                event,
                currencyValidation,
                setEntryFee,
                setEntryFeeError
              );
            }}
            handleClick={() => {
              handleClick(setEntryFeeClicked);
            }}
            inputAdornment={"£"}
            error={entryFeeError}
          />
          <DropDownField
            dropdownValue={dropdownValueWinners}
            menuItems={numberOfWinnersData}
            onChange={(event) => {
              handleDropdownChange(
                event,
                numberOfWinnersData,
                setNoOfWinners,
                setDropdownValueWinners,
                setNoOfWinnersError
              );
            }}
            handleClick={() => {
              handleClick(setNoOfWinnersClicked);
            }}
            error={noOfWinnersError ? noOfWinnersError : ""}
            label={PotCreatorForm.numberOfWinners}
          />
          <TextField
            value={noOfWinners}
            label={PotCreatorForm.payoutStructure}
            required={true}
            onChange={(event) => {
              handleTextChange(
                event,
                null,
                setNoOfWinners,
                setPayoutStructureError
              );
            }}
            handleClick={() => {
              handleClick(setPayoutStructureClicked);
            }}
            error={payoutStructureError}
          />
          <RadioField
            value={winningsMoneyRadio}
            label={PotCreatorForm.winningsPaid}
            onChange={(event) => {
              handleRadioChange(
                event,
                [PotBuilderFormOptions.money, PotBuilderFormOptions.freeBets],
                setWinningsMoney,
                setWinningsMoneyRadio
              );
            }}
            options={[
              PotBuilderFormOptions.money,
              PotBuilderFormOptions.freeBets,
            ]}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className={"H5BoldLeftSecondaryPressed"}>
            {PotBuilderFormHeaders.potInformation}
          </div>
          <TextField
            value={maxEntrants}
            label={PotCreatorForm.maxEntrants}
            required={true}
            onChange={(event) => {
              handleTextChange(
                event,
                numberValidation,
                setMaxEntrants,
                setMaxEntrantsError
              );
            }}
            error={maxEntrantsError}
          />
          <Checkbox
            checkboxLabels={[
              PotBuilderCappedOptions.capped120,
              PotBuilderCappedOptions.capped320,
              PotBuilderCappedOptions.capped510,
              PotBuilderCappedOptions.capped1010,
              PotBuilderCappedOptions.capped1510,
            ]}
            label={PotCreatorForm.capped}
            onChange={handleCheckbox}
            allDefaultChecked={false}
          />
          <RadioField
            value={pinnedRadio}
            label={PotCreatorForm.isPinned}
            onChange={(event) => {
              handleRadioChange(
                event,
                [PotBuilderFormOptions.true, PotBuilderFormOptions.false],
                setPinned,
                setPinnedRadio
              );
            }}
            options={[PotBuilderFormOptions.true, PotBuilderFormOptions.false]}
          />
          <RadioField
            value={typePublicRadio}
            label={PotCreatorForm.type}
            onChange={(event) => {
              handleRadioChange(
                event,
                [PotBuilderFormOptions.public, PotBuilderFormOptions.private],
                setTypePublic,
                setTypePublicRadio
              );
            }}
            options={[
              PotBuilderFormOptions.public,
              PotBuilderFormOptions.private,
            ]}
          />
          <RadioField
            value={potForNewUsersRadio}
            label={PotCreatorForm.potForNewUsers}
            onChange={(event) => {
              handleRadioChange(
                event,
                [PotBuilderFormOptions.true, PotBuilderFormOptions.false],
                setPotForNewUsers,
                setPotForNewUsersRadio
              );
            }}
            options={[PotBuilderFormOptions.true, PotBuilderFormOptions.false]}
          />
          <div className={"H5BoldLeftSecondaryPressed"}>
            {PotBuilderFormHeaders.gameInformation}
          </div>
          <TextField
            value={potCode}
            label={PotCreatorForm.potCode}
            onChange={(event) => {
              handleTextChange(
                event,
                potCodeValidation,
                setPotCode,
                setPotCodeError,
                true
              );
            }}
            handleClick={() => {
              handleClick(setPotCodeClicked);
            }}
            error={potCodeError}
          />
          <TextField
            value={maxPlayersPerClub}
            onChange={(event) => {
              handleTextChange(
                event,
                numberValidation,
                setMaxPlayersPerClub,
                setMaxPlayersPerClubError
              );
            }}
            handleClick={() => {
              handleClick(setMaxPlayersPerClubClicked);
            }}
            handleClickAway={() => {
              handleTextClickAway(
                setMaxPlayersPerClubError,
                maxPlayersPerClub,
                setMaxPlayersPerClubClicked,
                maxPlayersPerClubClicked,
                numberValidation,
                FormErrors.text
              );
            }}
            label={PotCreatorForm.maxPlayersPerClub}
            error={maxPlayersPerClubError}
          />
          <TimeField
            value={potStartTime}
            label={PotCreatorForm.potStartTime}
            onChange={(event) => {
              handleTimeChange(event, setPotStartTime);
            }}
            handleClick={() => {
              handleClick(setPotStartTimeClicked);
            }}
            dateOnly={false}
            error={potStartTimeError}
          />
          <TimeField
            value={displayTime}
            label={PotCreatorForm.displayTime}
            onChange={(event) => {
              handleTimeChange(event, setDisplayTime);
            }}
            dateOnly={false}
            error={displayTimeError}
          />
          <br />
          {submitButton(submitDisabled, buttonLoading, onSubmit)}
          <br />
          <br />
          <Button
            variant="outlined"
            sx={{
              width: "100%",
              padding: "8px",
            }}
            onClick={() => {
              clearAll();
            }}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Main;
