import { FeaturedBanners } from "../../interfaces/Carousel";
import { LoginContextInterface } from "../../interfaces/Login";
import { ApiResponse } from "../../interfaces/PotBuilderForm";
import { carouselURLs } from "../url";

const deleteFeaturedBanners = async (
    data: Array<FeaturedBanners>
  ): Promise<ApiResponse | undefined> => {
    const url = carouselURLs.deleteFeaturedBanner;
    try {
      const token = localStorage.getItem("loginInfo");
      if (token !== null) {
        const storedAccessToken: LoginContextInterface = JSON.parse(token);
        if (storedAccessToken.accessToken != null) {
          const response = await fetch(url, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${storedAccessToken.accessToken}`,
            },
            body: JSON.stringify({ data }),
          });
          try {
            const deleteFeaturedBannerResponse: ApiResponse = await response.json();
            return deleteFeaturedBannerResponse;
          } catch (err) {
            console.log(err);
          }
        }
      }
      return undefined;
    } catch (e) {
        console.log(e);
    return undefined;
    }
};

export default deleteFeaturedBanners;