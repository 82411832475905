import dayjs from "dayjs";
import type { LoginContextInterface } from "../../interfaces/Login";
import type {
  ApiResponse,
  PotBuilderFormData,
} from "../../interfaces/PotBuilderForm";
import { potBuilderURLs } from "../url";

const postFormData = async (
  data: PotBuilderFormData
): Promise<ApiResponse | undefined> => {
  const url = potBuilderURLs.postFormData;
  try {
    const token = localStorage.getItem("loginInfo");
    if (token !== null) {
      const storedAccessToken: LoginContextInterface = JSON.parse(token);
      if (storedAccessToken.accessToken != null) {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${storedAccessToken.accessToken}`,
          },
          body: JSON.stringify({ data }),
        });
        try {
          const fixturesResponse: ApiResponse = await response.json();
          return fixturesResponse;
        } catch (err) {
          console.log(err);
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export default postFormData;
