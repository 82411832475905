import {
  CSSProperties,
  ReactElement,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { FeaturedBanners, Svg } from "../interfaces/Carousel";
import { LoginInfoInterface } from "../interfaces/Login";
import { LoginContext } from "../context/LoginContext";
import useAsyncEffect from "use-async-effect";
import React from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Modal,
  Snackbar,
} from "@mui/material";
import Colors from "../config/Colors";
import { List, arrayMove } from "react-movable";
import dayjs from "dayjs";
import { CarouselModalForm } from "./CarouselModalForm";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import getFeaturedBannerData from "../api/Carousel/getFeaturedBanners";
import { AlertTextBanner, CarouselFormText } from "../config/Text";
import { clearAll } from "../screens/Main";
import editFeaturedBannerData from "../api/Carousel/updateFeaturedBannerData";
import createFeaturedBanners from "../api/Carousel/createFeaturedBanners";
import deleteFeaturedBanners from "../api/Carousel/deleteFeaturedBanners";
import postFeaturedBannerSvg from "../api/Carousel/postFeaturedBannerSvgs";
import GanttChart from "./GanttChart";
import utc from "dayjs/plugin/utc";

const tableStyles = {
  background: "#eaebec",
  borderSpacing: 0,
};

const columnNames = [
  "Name",
  "Type",
  "Order",
  "Pot Code",
  "Show From Date/Time",
  "Show Until Date/Time",
  "",
  "",
];

const modalStyle = [
  {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    padding: "3em",
    minWidth: "630px",
  },
  {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "20%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    padding: "3em",
    minWidth: "430px",
    minHeight: "250px",
    maxWidth: "430px",
    maxHeight: "240px",
  },
];

const tableButtonStyling = {
  minWidth: "151px",
  maxHeight: "37px",
};

const thStyles = {
  borderBottom: "2px solid #ddd",
  padding: "30px",
  textAlign: "center",
} as CSSProperties;

const tdStyles = (width?: string): CSSProperties => ({
  borderBottom: "1px solid #ddd",
  padding: "24px",
  textAlign: "center",
  width,
});

interface BannerInterface {
  featuredBanner?: FeaturedBanners;
}

export const DraggableTable = () => {
  const [widths, setWidths] = useState<string[]>([]);
  const [featuredBannerData, setFeaturedBannerData] = useState<
    Array<FeaturedBanners>
  >([]);
  const { loginInfo } = useContext<LoginInfoInterface>(LoginContext);
  const [open, setOpen] = useState(false);
  const [currentEdit, setCurrentEdit] = useState<FeaturedBanners | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [modalBannerData, setModalBannerData] =
    useState<FeaturedBanners | null>(null);
  const [modalEditType, setModalEditType] = useState<boolean>(false);
  const [deletionModal, setDeletionModal] = useState<boolean>(false);
  const [modalSave, setModalSave] = useState<boolean>(false);
  const [lastBannerOrder, setLastBannerOrder] = useState<number | null>(null);
  const [dataLength, setDataLength] = useState<number | null>(null);

  const [deletedBanners, setDeletedBanners] = useState<Array<FeaturedBanners>>(
    []
  );
  const [createdBanners, setCreatedBanners] = useState<Array<FeaturedBanners>>(
    []
  );
  const [alert, setAlert] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertErrorMessage, setAlertErrorMessage] = useState<string>("");
  const [progress, setProgress] = useState<number>();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [latestId, setLatestId] = useState<number>(0);

  const [createdBannerSvgs, setCreatedBannerSvgs] = useState<Array<Svg>>([]);

  dayjs.extend(utc);

  const handleOpen = (featuredBanner: FeaturedBanners) => {
    setCurrentEdit(featuredBanner);
    setDataLength(featuredBannerData.length);
    setLastBannerOrder(featuredBannerData.length);
    setModalBannerData(featuredBanner);
    setModalEditType(true);
    setOpen(true);
  };

  const handleSumbitData = async () => {
    let newTempFeaturedBannersEdit: Array<FeaturedBanners> = [];

    featuredBannerData.forEach(
      (featuredBanner: FeaturedBanners, index: number) => {
        if (
          featuredBanner.featuredBannerId !== null &&
          !createdBanners.includes(featuredBanner)
        ) {
          newTempFeaturedBannersEdit.push(featuredBanner);
        }
      }
    );

    try {
      setAlert("progress");
      setProgress(0);
      setAlertOpen(true);
      if (newTempFeaturedBannersEdit.length > 0) {
        const editFormData = await editFeaturedBannerData(
          newTempFeaturedBannersEdit
        );

        if (editFormData && editFormData.success === false) {
          setAlertErrorMessage(`${editFormData.message}`);
          setAlert("error");
        } else {
          setProgress(25);
          if (createdBannerSvgs.length !== 0) {
            await postFeaturedBannerSvg(createdBannerSvgs);
          }
          setProgress(50);
          if (createdBanners.length !== 0) {
            await createFeaturedBanners(createdBanners);
          }
          setProgress(75);
          if (deletedBanners.length !== 0) {
            await deleteFeaturedBanners(deletedBanners);
          }
          setProgress(100);
          setButtonLoading(false);
          setAlert("success");
          setAlertOpen(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
    handleClose();
  };

  const handleOpenCreateModal = () => {
    let tempFeaturedBanner: FeaturedBanners = {
      featuredBannerId: 0,
      name: "",
      type: "Pot",
      order: 0,
      potCode: null,
      showFromDateTimeUtc: "",
      showUntilDateTimeUtc: "",
    };

    setCurrentEdit(tempFeaturedBanner);
    setDataLength(featuredBannerData.length);
    setLastBannerOrder(featuredBannerData.length);
    setModalBannerData(tempFeaturedBanner);
    setModalEditType(false);
    setOpen(true);
  };

  const handleOpenDeleteModal = (featuredBanner: FeaturedBanners) => {
    setModalBannerData(featuredBanner);
    setDeletionModal(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeletionModal(false);
    setModalSave(false);
  };

  const handleOpenSaveModal = () => {
    setModalSave(true);
    setOpen(true);
  };

  useAsyncEffect(async () => {
    if (featuredBannerData.length === 0 && deletedBanners.length === 0) {
      await handleSetData();
    }
  });

  useEffect(() => {
    if (
      currentEdit !== null &&
      currentEdit.name !== "" &&
      modalEditType === false
    ) {
      let tempData: FeaturedBanners[] = featuredBannerData;
      let tempCreatedBanners: FeaturedBanners[] = createdBanners;

      if (currentEdit.order <= featuredBannerData.length) {
        tempData[currentEdit.order - 1] = currentEdit;
      } else {
        tempData.push(currentEdit);
      }

      tempCreatedBanners.push(currentEdit);
      setCreatedBanners(tempCreatedBanners);
      setFeaturedBannerData(tempData);
      setCurrentEdit(null);
    } else if (
      currentEdit !== null &&
      currentEdit.name !== "" &&
      modalEditType === true
    ) {
      let tempData: FeaturedBanners[] = featuredBannerData;
      let editedBanners: FeaturedBanners[] = [];

      tempData.forEach((featuredBanner: FeaturedBanners) => {
        if (featuredBanner.order === currentEdit.order) {
          editedBanners.push(currentEdit);
        } else {
          editedBanners.push(featuredBanner);
        }
      });

      setFeaturedBannerData(editedBanners);
      setCurrentEdit(null);
    }
  }, [currentEdit]);

  useEffect(() => {
    let tempCreatedBanners: Array<FeaturedBanners> = [];
    let outOfOrder: boolean;

    featuredBannerData.forEach((banner: FeaturedBanners) => {
      createdBanners.forEach((createdBanner: FeaturedBanners) => {
        if (
          banner.name === createdBanner.name &&
          banner.order !== createdBanner.order
        ) {
          tempCreatedBanners.push(banner);
          outOfOrder = true;
        }
      });
    });

    let sortedArray = tempCreatedBanners.sort((a, b) =>
      a.order > b.order ? 1 : -1
    );
    if (outOfOrder === true) {
      setCreatedBanners(sortedArray);
    }
  }, [featuredBannerData]);

  useEffect(() => {
    featuredBannerData.forEach((banner) => {
      if (latestId === 0 || banner.featuredBannerId > latestId) {
        setLatestId(banner.featuredBannerId);
      }
    });
  }, [featuredBannerData]);

  const handleSetData = async () => {
    if (loginInfo === null || loginInfo === undefined) return;
    setLoading(true);
    try {
      const data: FeaturedBanners[] = await getFeaturedBannerData();
      if (data) {
        const sortedArray = data.sort(
          (a: FeaturedBanners, b: FeaturedBanners) => {
            return a.order - b.order;
          }
        );

        const dateAmmendedArray: Array<FeaturedBanners> = [];
        sortedArray.forEach((banner: FeaturedBanners) => {
          let tempShowFrom = banner.showFromDateTimeUtc;
          if (banner.showFromDateTimeUtc === null) {
            tempShowFrom = dayjs().format();
          }

          let tempBanner: FeaturedBanners = {
            featuredBannerId: banner.featuredBannerId,
            name: banner.name,
            type: banner.type,
            order: banner.order,
            potCode: banner.potCode,
            showFromDateTimeUtc: tempShowFrom,
            showUntilDateTimeUtc: banner.showUntilDateTimeUtc,
          };

          if (latestId === 0 || banner.featuredBannerId > latestId) {
            setLatestId(banner.featuredBannerId);
          }

          dateAmmendedArray.push(tempBanner);
        });
        setFeaturedBannerData(dateAmmendedArray);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleReshuffle = async (bannerData: Array<FeaturedBanners>) => {
    let reshuffledFeaturedBannerData: Array<FeaturedBanners> = [];

    bannerData.forEach((featuredBanner: FeaturedBanners, index: number) => {
      let tempFeaturedBanner: FeaturedBanners = {
        featuredBannerId: featuredBanner.featuredBannerId,
        name: featuredBanner.name,
        type: featuredBanner.type,
        order: index + 1,
        potCode: featuredBanner.potCode,
        showFromDateTimeUtc: featuredBanner.showFromDateTimeUtc,
        showUntilDateTimeUtc: featuredBanner.showUntilDateTimeUtc,
      };

      reshuffledFeaturedBannerData.push(tempFeaturedBanner);
    });
    setFeaturedBannerData(reshuffledFeaturedBannerData);
  };

  const handleDelete = (banner: FeaturedBanners) => {
    let tempBannerData: Array<FeaturedBanners> = [];
    let tempDeletedBanners: Array<FeaturedBanners> = deletedBanners;
    let tempCreatedBanners: Array<FeaturedBanners> = [];
    let tempCreatedBannerSvgs: Array<Svg> = [];
    let createdBannerFound: boolean;

    featuredBannerData.forEach((currentBanner: FeaturedBanners) => {
      if (banner.order !== currentBanner.order) {
        tempBannerData.push(currentBanner);
      } else {
        tempDeletedBanners.push(currentBanner);
        // searches createdBannerSvgs array and removes an svg from createdBannerSvgs array
        createdBannerSvgs.forEach((createdBannerSvg: Svg) => {
          if (createdBannerSvg.imageName !== banner.name) {
            tempCreatedBannerSvgs.push(createdBannerSvg);
          }
        });
      }
    });

    if (createdBanners.length > 0) {
      createdBanners.forEach((createdBanner: FeaturedBanners) => {
        if (banner.name === createdBanner.name) {
          createdBannerFound = true;
        } else {
          tempCreatedBanners.push(createdBanner);
        }
      });
    }

    setDeletedBanners(tempDeletedBanners);
    setFeaturedBannerData(tempBannerData);
    setCreatedBannerSvgs(tempCreatedBannerSvgs);
    if (createdBannerFound === true) {
      setCreatedBanners(tempCreatedBanners);
    }
    handleClose();
    handleReshuffle(tempBannerData);
  };

  const getColumns = (columnNames: Array<string>) => {
    return (
      <>
        {columnNames.map((columnName: string, index: number) => (
          <th key={index} style={thStyles}>
            {columnName}
          </th>
        ))}
      </>
    );
  };

  const Confirm = ({ featuredBanner }: BannerInterface): ReactElement => {
    return (
      <>
        <Box
          style={{
            alignContent: "center",
          }}
        >
          {featuredBanner
            ? `${CarouselFormText.confrimDeletion}'${featuredBanner.name}'`
            : CarouselFormText.confirmSave}
          <Box
            style={{
              padding: "3em",
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                width: "80%",
                padding: "8px",
                backgroundColor: featuredBanner
                  ? Colors.Error400
                  : Colors.Primary600,
                color: Colors.Base050,
              }}
              onClick={() => {
                featuredBanner
                  ? handleDelete(featuredBanner)
                  : handleSumbitData();
              }}
            >
              {featuredBanner ? "Delete" : "Submit"}
            </Button>
            <Button
              variant="outlined"
              sx={{ width: "80%", padding: "8px" }}
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  const ModalType = (): ReactElement => {
    if (deletionModal === true) {
      return (
        <Box sx={modalStyle[1]}>
          <Confirm featuredBanner={modalBannerData} />{" "}
        </Box>
      );
    } else if (modalSave === true) {
      return (
        <Box sx={modalStyle[1]}>
          <Confirm />{" "}
        </Box>
      );
    } else {
      return (
        <Box sx={modalStyle[0]}>
          <CarouselModalForm
            edit={modalEditType}
            featuredBanner={modalBannerData}
            setFeaturedBanner={setCurrentEdit}
            latestId={latestId}
            setLatestId={setLatestId}
            lastBannerOrder={lastBannerOrder}
            handleClose={handleClose}
            createdBannerSvgs={createdBannerSvgs}
            setCreatedBannerSvgs={setCreatedBannerSvgs}
          />
        </Box>
      );
    }
  };

  const handleAlertClose = (
    _event: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
    if (alert === "success") {
      clearAll();
    }
  };

  const changeAlert = (
    severity: AlertColor | undefined,
    text: string,
    autoHide?: boolean
  ) => {
    return (
      <Snackbar
        open={alertOpen}
        autoHideDuration={autoHide ? null : 6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {text}
        </Alert>
      </Snackbar>
    );
  };

  const alertBanner = () => {
    if (alert === "success") {
      return changeAlert("success", AlertTextBanner.success);
    } else if (alert === "progress") {
      return (
        <Snackbar open={alertOpen} onClose={handleAlertClose}>
          <Alert
            onClose={handleAlertClose}
            severity="warning"
            sx={{ width: "100%" }}
          >
            {AlertTextBanner.progress}
            <LinearProgress variant="determinate" value={progress} />
          </Alert>
        </Snackbar>
      );
    } else {
      return changeAlert("error", alertErrorMessage);
    }
  };

  return (
    <div>
      {alertBanner()}
      <div style={{ textAlign: "center", padding: "3em" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0 20em",
            gap: "60px",
          }}
        >
          <Button
            variant="outlined"
            style={{ minWidth: "151px", maxHeight: "37px" }}
            onClick={() => handleReshuffle(featuredBannerData)}
          >
            Reshuffle Order
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: Colors.Primary600,
              color: Colors.Base050,
              minWidth: "151px",
              maxHeight: "37px",
            }}
            onClick={() => {
              handleOpenCreateModal();
            }}
          >
            Create Banner
          </Button>
        </div>
        <div
          style={{
            padding: "3em",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <List
            beforeDrag={({ elements, index }) => {
              const cells = Array.from(elements[index].children);
              const widths = cells.map(
                (cell) => window.getComputedStyle(cell).width
              );
              setWidths(widths);
            }}
            values={featuredBannerData}
            onChange={({ oldIndex, newIndex }) => {
              handleReshuffle(
                arrayMove(featuredBannerData, oldIndex, newIndex)
              );
            }}
            renderList={({ children, props, isDragged }) => (
              <table
                style={{
                  ...tableStyles,
                  cursor: isDragged ? "grabbing" : undefined,
                }}
              >
                <thead>
                  <tr>{getColumns(columnNames)}</tr>
                </thead>
                <tbody {...props}>{children}</tbody>
              </table>
            )}
            renderItem={({ value, props, isDragged, isSelected }) => {
              const _widths = isDragged ? widths : [];
              const potCode = value.potCode === null ? "N/A" : value.potCode;
              const row = (
                <tr
                  {...props}
                  style={{
                    ...props.style,
                    cursor: isDragged ? "grabbing" : "grab",
                    backgroundColor:
                      isDragged || isSelected ? "#EEE" : "#fafafa",
                  }}
                >
                  <td style={tdStyles(_widths[0])}>{value.name}</td>
                  <td style={tdStyles(_widths[1])}>{value.type}</td>
                  <td style={tdStyles(_widths[2])}>{value.order}</td>
                  <td style={tdStyles(_widths[3])}>{potCode}</td>
                  <td style={tdStyles(_widths[5])}>
                    {dayjs(value.showFromDateTimeUtc).format("DD/MM/YY HH:mm")}
                  </td>
                  <td style={tdStyles(_widths[5])}>
                    {dayjs(value.showUntilDateTimeUtc).format("DD/MM/YY HH:mm")}
                  </td>
                  <td style={tdStyles(_widths[6])}>
                    {
                      <IconButton onClick={() => handleOpen(value)}>
                        <ModeEditOutlineOutlinedIcon />
                      </IconButton>
                    }
                  </td>
                  <td style={tdStyles(_widths[7])}>
                    {
                      <IconButton
                        color="error"
                        onClick={() => {
                          handleOpenDeleteModal(value);
                        }}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    }
                  </td>
                </tr>
              );

              return isDragged ? (
                <table style={{ ...props.style, borderSpacing: 0 }}>
                  <tbody>{row}</tbody>
                </table>
              ) : (
                row
              );
            }}
          />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={
                deletionModal === true
                  ? { minWidth: "430px", minHeight: "250px" }
                  : { minWidth: "400px", minHeight: "600px" }
              }
            >
              <ModalType />
            </div>
          </Modal>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 20em",
            gap: "60px",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              backgroundColor: Colors.Primary600,
              color: Colors.Base050,
              minWidth: "151px",
              maxHeight: "37px",
            }}
            onClick={() => {
              handleOpenSaveModal();
            }}
          >
            Save Changes
          </Button>
        </div>
      </div>
      {featuredBannerData.length !== 0 ? (
        <GanttChart bannerData={featuredBannerData} />
      ) : (
        ""
      )}
    </div>
  );
};
