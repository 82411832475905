import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
} from "@mui/material";
import type { ReactElement } from "react";
import React from "react";

interface radioInterface {
  value: string;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  options: Array<string>;
}

const RadioField = ({
  value,
  label,
  onChange,
  options,
}: radioInterface): ReactElement => {
  const radioOptions = () => {
    let tempRadio = [];
    options.forEach((option, i) =>
      tempRadio.push(
        <FormControlLabel
          key={i}
          value={option}
          control={<Radio required />}
          label={option}
        />
      )
    );
    return tempRadio;
  };

  return (
    <Box sx={{ padding: "16px 0px 16px 0px" }}>
      <FormLabel id="demo-radio-buttons-group" sx={{ fontSize: "12px" }}>
        {label}
      </FormLabel>
      <MuiRadioGroup
        row
        aria-labelledby="demo-radio-buttons-group"
        name="radio-buttons-group"
        value={value}
        onChange={onChange}
      >
        {radioOptions()}
      </MuiRadioGroup>
    </Box>
  );
};

export default RadioField;
