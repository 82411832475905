import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Button, Grid, IconButton, Table, Typography } from "@mui/material";
import { Box } from "@mui/system";
import dayjs, { Dayjs } from "dayjs";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import Colors from "../config/Colors";
import {
  CarouselFormText,
  CarouselTypeOptions,
  FormErrors,
} from "../config/Text";
import { FeaturedBanners, Svg } from "../interfaces/Carousel";
import RadioField from "./Radio";
import TextField from "./TextField";
import TimeField from "./TimeField";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {
  handleClick,
  handleRadioChange,
  handleTextChange,
  handleTimeChange,
} from "../screens/Main";
import {
  bannerFileNameValidation,
  potCodeValidation,
} from "../config/Validation";
import { LoginContextInterface } from "../interfaces/Login";
import { carouselURLs } from "../api/url";

interface CarouselModalFormInterface {
  featuredBanner: FeaturedBanners | null;
  edit: boolean;
  setFeaturedBanner: React.Dispatch<
    React.SetStateAction<FeaturedBanners | null>
  >;
  lastBannerOrder: number | null;
  latestId: number | null;
  setLatestId: React.Dispatch<React.SetStateAction<number>>;
  handleClose: () => void;
  createdBannerSvgs: Array<Svg>;
  setCreatedBannerSvgs: React.Dispatch<React.SetStateAction<Array<Svg>>>;
}

export const CarouselModalForm = ({
  featuredBanner,
  edit,
  setFeaturedBanner,
  lastBannerOrder,
  latestId,
  setLatestId,
  handleClose,
  createdBannerSvgs,
  setCreatedBannerSvgs,
}: CarouselModalFormInterface): ReactElement => {
  if (featuredBanner) {
    let tempBannerName: string = featuredBanner.name;
    if (
      featuredBanner.name.substring(featuredBanner.name.length - 4) === ".svg"
    ) {
      tempBannerName = featuredBanner.name.substring(
        0,
        featuredBanner.name.length - 4
      );
    }
    const [name, setName] = useState<string>(tempBannerName);
    const [potCode, setPotCode] = useState<string | null>(
      featuredBanner.potCode
    );
    const [type, setType] = useState<boolean>(true);
    const [typeRadio, setTypeRadio] = useState<string>(featuredBanner.type);
    let showFromDayjs = null;
    let showUntilDayjs = null;

    if (
      featuredBanner.showFromDateTimeUtc !== "" &&
      featuredBanner.showUntilDateTimeUtc !== ""
    ) {
      showFromDayjs = dayjs(featuredBanner.showFromDateTimeUtc);
      showUntilDayjs = dayjs(featuredBanner.showUntilDateTimeUtc);
    }

    const [showFromDateTimeUtc, setShowFromDateTimeUtc] =
      useState<Dayjs | null>(showFromDayjs);
    const [showUntilDateTimeUtc, setShowUntilDateTimeUtc] =
      useState<Dayjs | null>(showUntilDayjs);

    const [nameError, setNameError] = useState<string>("");
    const [potCodeError, setPotCodeError] = useState<string>("");
    const [showFromDateTimeUtcError, setShowFromDateTimeUtcError] =
      useState<string>("");
    const [showUntilDateTimeUtcError, setShowUntilDateTimeUtcError] =
      useState<string>("");

    const [nameClicked, setNameClicked] = useState<boolean | null>();
    const [potCodeClicked, setPotCodeClicked] = useState<boolean | null>();
    const [showFromDateTimeUtcClicked, setShowFromDateTimeUtcClicked] =
      useState<boolean | null>();
    const [showUntilDateTimeUtcClicked, setShowUntilDateTimeUtcClicked] =
      useState<boolean | null>();

    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
      if (name.length === 0 && nameClicked === true) {
        setNameError(FormErrors.text);
      } else if (name.match(bannerFileNameValidation) === null) {
        setNameError(FormErrors.fileName);
      } else {
        setNameError("");
      }
    }, [name]);

    useEffect(() => {
      if (potCode !== null && potCode.length > 25 && potCodeClicked === true) {
        setPotCodeError(FormErrors.text);
      } else {
        setPotCodeError("");
      }
    }, [potCode]);

    useEffect(() => {
      if (showFromDateTimeUtc === null && showFromDateTimeUtcClicked === true) {
        setShowFromDateTimeUtcError(FormErrors.time);
      } else if (showFromDateTimeUtc !== null) {
        setShowFromDateTimeUtcError("");
      }
    }, [showFromDateTimeUtc]);

    useEffect(() => {
      if (edit === false) {
        setPotCode("");
      }
    }, [typeRadio]);

    useEffect(() => {
      let isAfterShowFrom: boolean = false;

      if (
        showUntilDateTimeUtc &&
        showUntilDateTimeUtc.isAfter(showFromDateTimeUtc)
      ) {
        isAfterShowFrom = true;
      }

      if (
        showUntilDateTimeUtc === null &&
        showUntilDateTimeUtcClicked === true
      ) {
        setShowUntilDateTimeUtcError(FormErrors.time);
      } else if (
        isAfterShowFrom === false &&
        showUntilDateTimeUtcClicked === true &&
        showUntilDateTimeUtc !== null
      ) {
        setShowUntilDateTimeUtcError(FormErrors.timeBefore);
      } else if (isAfterShowFrom === true && showUntilDateTimeUtc !== null) {
        setShowUntilDateTimeUtcError("");
      }
    }, [showUntilDateTimeUtc]);

    useEffect(() => {
      let isError =
        nameError === "" &&
        potCodeError === "" &&
        showFromDateTimeUtcError === "" &&
        showUntilDateTimeUtcError === "" &&
        name.length !== 0 &&
        showFromDateTimeUtc !== null &&
        showUntilDateTimeUtc !== null;

      setSubmitDisabled(!isError);

      return () => {
        isError = false;
      };
    }, [
      name,
      potCode,
      showFromDateTimeUtc,
      showUntilDateTimeUtc,
      nameError,
      potCodeError,
      showFromDateTimeUtcError,
      showUntilDateTimeUtcError,
    ]);

    const UploadImages = () => {
      const onSelectFile = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0];
        const convertedFile = await convertToBase64(file);
        const convertedFileString = String(convertedFile);

        const tempSvg: Svg = {
          image: convertedFileString,
          imageName: file.name,
        };

        let tempSvgArray: Svg[] = createdBannerSvgs;

        tempSvgArray.push(tempSvg);
        setCreatedBannerSvgs(tempSvgArray);
      };
      const convertToBase64 = (file: Blob) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            resolve(reader.result);
          };
        });
      };
      return (
        <Box sx={{ padding: "16px 0px 16px 0px" }}>
          <input type="file" accept=".svg" onChange={onSelectFile} />
        </Box>
      );
    };

    const submitButton = (): ReactElement => {
      const buttonStyle = {
        width: "100%",
        padding: "8px",
        backgroundColor: submitDisabled ? Colors.Base400 : Colors.Primary600,
        color: Colors.Base050,
      };

      if (buttonLoading === false) {
        return (
          <Button
            disabled={submitDisabled}
            sx={buttonStyle}
            onClick={() => {
              onSubmit();
            }}
          >
            Submit
          </Button>
        );
      } else {
        return (
          <LoadingButton loading variant="outlined" sx={buttonStyle}>
            Submit
          </LoadingButton>
        );
      }
    };

    const onSubmit = async () => {
      let showFromString = dayjs(showFromDateTimeUtc).format();
      let showUntilString = dayjs(showUntilDateTimeUtc).format();

      if (edit === true) {
        let tempFeaturedBanner: FeaturedBanners = {
          featuredBannerId: featuredBanner.featuredBannerId,
          name: name + ".svg",
          type: typeRadio,
          order: featuredBanner.order,
          potCode,
          showFromDateTimeUtc: showFromString,
          showUntilDateTimeUtc: showUntilString,
        };

        setFeaturedBanner(tempFeaturedBanner);
        handleClose();
      } else {
        if (lastBannerOrder !== null && lastBannerOrder !== null) {
          let potCodeTemp: string | null = potCode;
          if (typeRadio === "RABM") {
            potCodeTemp = null;
          }

          let tempFeaturedBanner: FeaturedBanners = {
            featuredBannerId: latestId + 1,
            name: name + ".svg",
            type: typeRadio,
            order: lastBannerOrder + 1,
            potCode: potCodeTemp,
            showFromDateTimeUtc: showFromString,
            showUntilDateTimeUtc: showUntilString,
          };

          setLatestId(latestId + 1);

          setFeaturedBanner(tempFeaturedBanner);
          handleClose();
        }
      }
    };

    return (
      <Box sx={{ alignContent: "center" }}>
        <Box display="flex" sx={{ position: "relative", float: "right" }}>
          <IconButton
            onClick={() => {
              handleClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography sx={{ paddingBottom: 2 }}>
          {edit === true ? "Edit " : "Create "} Featured Banner
        </Typography>{" "}
        <TextField
          value={name}
          label={CarouselFormText.name}
          onChange={(event) => {
            handleTextChange(
              event,
              bannerFileNameValidation,
              setName,
              setNameError,
              true
            );
          }}
          inputAdornment={".svg"}
          handleClick={() => {
            handleClick(setNameClicked);
          }}
          error={nameError}
          inputAdornmentEnd={true}
        />
        <RadioField
          value={typeRadio}
          label={CarouselFormText.type}
          onChange={(event) => {
            handleRadioChange(
              event,
              [CarouselTypeOptions.pot, CarouselTypeOptions.rabm],
              setType,
              setTypeRadio
            );
          }}
          options={[CarouselTypeOptions.pot, CarouselTypeOptions.rabm]}
        />
        {typeRadio === "RABM" ? (
          ""
        ) : (
          <TextField
            value={potCode}
            label={CarouselFormText.potCode}
            onChange={(event) => {
              handleTextChange(
                event,
                potCodeValidation,
                setPotCode,
                setPotCodeError,
                true
              );
            }}
            handleClick={() => {
              handleClick(setPotCodeClicked);
            }}
            error={potCodeError}
          />
        )}
        <TimeField
          value={showFromDateTimeUtc}
          label={CarouselFormText.showFromDateTimeUtc}
          onChange={(event) => {
            handleTimeChange(event, setShowFromDateTimeUtc);
          }}
          handleClick={() => {
            handleClick(setShowFromDateTimeUtcClicked);
          }}
          dateOnly={false}
          error={showFromDateTimeUtcError}
        />
        <TimeField
          value={showUntilDateTimeUtc}
          label={CarouselFormText.showUntilDateTimeUtc}
          onChange={(event) => {
            handleTimeChange(event, setShowUntilDateTimeUtc);
          }}
          handleClick={() => {
            handleClick(setShowUntilDateTimeUtcClicked);
          }}
          dateOnly={false}
          error={showUntilDateTimeUtcError}
        />
        {edit === false ? <UploadImages /> : ""}
        <br />
        <br />
        {submitButton()}
      </Box>
    );
  } else {
    return <></>;
  }
};
