import { Api } from "@mui/icons-material";

const getBaseUrl =
  window.location.origin === "https://nimda.betmate.app"
    ? "https://5vuextc2z5.execute-api.eu-west-2.amazonaws.com/api/"
    : "https://8x5eiq4p3m.execute-api.eu-west-2.amazonaws.com/api/"; 

export const potBuilderURLs = {
  getFixtures: getBaseUrl + "fixtures",
  postFormData: getBaseUrl + "potBuilder",
};

export const selectorURLs = {
  postSelectorFormData: getBaseUrl + "postSelectorFormData"
}

export const metricURLs = {
  getUserMetrics: getBaseUrl + "getUserMetrics",
  getUsageMetrics: getBaseUrl + "getUsageMetrics",
  getUsageStakesMetrics: getBaseUrl + "getUsageStakesMetrics",
  getBettingMetrics: getBaseUrl + "getBettingMetrics",
};

export const carouselURLs = {
  getFeaturedBannerData: getBaseUrl + "getFeaturedCarousel",
  editFeaturedBannerData: getBaseUrl + "updateFeaturedBanners",
  deleteFeaturedBanner: getBaseUrl + "deleteFeaturedBanner",
  createFeaturedBannerData: getBaseUrl + "createFeaturedBanner",
  postFeaturedBannerSvg: getBaseUrl + "postFeaturedBannerSvg"
}

export const freeBetUrls = {
  addFreeBets: getBaseUrl + "addFreeBets"
}

export const userDataUrls = {
  getUserData: getBaseUrl + "getUserData",
  getUserTransactions: getBaseUrl + "getUserTransactions",
  getUserPots: getBaseUrl + "getUserPots",
  getUserReferrals: getBaseUrl + "getUserReferrals",
}

export const verifiedUserDataUrls = {
  postVerifiedUserData: getBaseUrl + "postVerifiedUserData"
}
