import type { ReactElement } from "react";
import React from "react";

const BetmateLogo = (): ReactElement => {
  return (
    <svg width="180" height="45" viewBox="0 0 180 45">
      <defs>
        <path
          d="M22.447 0C10.051 0 0 10.048 0 22.443 0 34.84 10.05 44.887 22.447 44.887c12.397 0 22.447-10.048 22.447-22.444C44.894 10.048 34.844 0 22.447 0zm.05 1.862c10.846 0 20.633 9.786 20.633 20.63s-9.787 20.63-20.634 20.63-20.634-9.786-20.634-20.63 9.787-20.63 20.634-20.63z"
          id="bev70mthia"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <ellipse
            fill="#182944"
            cx="22.447"
            cy="22.492"
            rx="22.447"
            ry="22.386"
          />
          <path fill="#00F2C1" d="M20.694 7.154h2.054v3.987h-2.054z" />
          <path
            fill="#FFF"
            d="m25.553 11.838 2.833-2.82 1.417 1.41-2.833 2.82zM13.532 10.427l1.416-1.41 2.833 2.82-1.416 1.41zM30.123 19.112c-1.647-2.017-4.167-3.497-6.983-3.87l-6.098 6.044.724.698c1.167 1.177 3.08 1.177 4.267 0l2.133-2.114 1.952 2.294c1.167 1.156 3.08 1.156 4.267 0l1.429 1.396a5.035 5.035 0 0 1-4.327 1.396l4.005 6.123h10.695c.812-2.68 1.218-4.72 1.218-6.123 0-1.403-.025-3.351-.077-5.844H30.123z"
          />
          <path
            d="M21.691 35.067c2.99 0 5.838-1.22 7.905-3.34l-5.678-8.72-.36.36c-2.024 1.975-5.19 1.928-7.103-.02l-2.146-2.08 6.139-6.138a11.164 11.164 0 0 0-7.263 3.94H1.72c-.437 2.577-.655 4.539-.655 5.886 0 1.347.75 3.384 2.253 6.112h9.868a11.07 11.07 0 0 0 8.506 4z"
            fill="#00F2C1"
          />
          <g>
            <path
              d="M22.447.049C10.051.049 0 10.097 0 22.492c0 12.396 10.05 22.444 22.447 22.444 12.397 0 22.447-10.048 22.447-22.444C44.894 10.097 34.844.05 22.447.05zm.05 1.862c10.846 0 20.633 9.786 20.633 20.63s-9.787 20.63-20.634 20.63-20.634-9.786-20.634-20.63 9.787-20.63 20.634-20.63z"
              fill="#00F2C1"
              fillRule="nonzero"
            />
            <g transform="translate(0 .049)">
              <mask id="grskfsw0wb" fill="#fff">
                <use />
              </mask>
              <use fill="#00F2C1" />
              <path
                fill="#FFF"
                mask="url(#grskfsw0wb)"
                d="M-24.8-36.311h46.659v91.048H-24.8z"
              />
            </g>
          </g>
        </g>
        <path
          d="M64.221 32.635c2.001 0 3.532-.497 4.593-1.49 1.061-.995 1.592-2.464 1.592-4.41 0-1.255-.253-2.234-.758-2.934-.505-.701-1.243-1.209-2.213-1.523a4.14 4.14 0 0 0 1.652-1.71c.394-.743.591-1.637.591-2.683 0-1.758-.485-3.107-1.455-4.049-.97-.941-2.425-1.412-4.366-1.412h-9.883v20.21h10.247zM63.1 20.803h-5.033v-5.24h4.73c1.131 0 1.924.214 2.38.643.454.429.681 1.103.681 2.024 0 1.716-.92 2.573-2.758 2.573zm.151 8.725h-5.184v-5.649h4.911c1.193 0 2.037.214 2.532.643.495.43.742 1.104.742 2.025 0 1.087-.237 1.856-.712 2.306-.475.45-1.238.675-2.289.675zm22.966 3.107V29.37h-9.913v-5.21h8.792v-3.263h-8.792v-5.179h9.913v-3.295H72.211v20.21h14.006zm12.373 0V15.688h5.972v-3.264H88.494v3.264h6.003v16.947h4.093z"
          fill="#00F2C1"
        />
        <path
          d="M113.08 32.635v-13.15l4.581 9.666h2.366l4.55-9.666v13.15h4.096v-20.21h-4.095l-5.734 12.144-5.733-12.145h-4.126v20.21h4.095zm21.761 0 1.82-4.865h7.19l1.85 4.865h4.247l-7.432-20.21h-4.49l-7.462 20.21h4.277zm8.403-8.128h-5.976l2.973-8.129 3.003 8.129zm13.922 8.128V15.688h5.976v-3.264h-16.078v3.264h6.007v16.947h4.095zm22.034 0V29.37h-9.92v-5.21h8.797v-3.263h-8.797v-5.179h9.92v-3.295h-14.015v20.21H179.2z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};

export default BetmateLogo;
