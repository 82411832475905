import { Box, FormControl, TextField } from "@mui/material";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import type { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import type { MouseEventHandler, ReactElement } from "react";
import React from "react";

interface timeInterface {
  value: Dayjs | null;
  label: string;
  onChange: (
    value: Dayjs | null,
    keyboardInputValue?: string | undefined
  ) => void;
  handleClick?: MouseEventHandler<HTMLDivElement>;
  dateOnly: boolean;
  error?: string | null;
}

const TimeField = ({
  value,
  label,
  onChange,
  handleClick,
  dateOnly,
  error,
}: timeInterface): ReactElement => {
  return (
    <Box sx={{ padding: "16px 0px 16px 0px" }}>
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {dateOnly === false ? (
            <DateTimePicker
              label={label}
              value={value}
              onChange={onChange}
              renderInput={(props) => (
                <TextField
                  {...props}
                  onClick={handleClick ? handleClick : undefined}
                  sx={{ width: "100%" }}
                  error={!!error}
                  helperText={error}
                />
              )}
            />
          ) : (
            <DatePicker
              label={label}
              value={value}
              onChange={onChange}
              inputFormat="DD/MM/YYYY"
              renderInput={(props) => (
                <TextField
                  {...props}
                  onClick={handleClick ? handleClick : undefined}
                  sx={{ width: "100%" }}
                  error={!!error}
                  helperText={error}
                />
              )}
            />
          )}
        </LocalizationProvider>
      </FormControl>
    </Box>
  );
};

export default TimeField;
