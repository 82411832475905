import { Box, styled } from "@mui/material";
import {
  DataGrid,
  DataGrid as MuiDataGrid,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import React from "react";
import { HashRouter } from "react-router-dom";
import { UserData } from "../interfaces/UserData";

interface UserInfoDisplayInterface {
  userData: UserData;
}

const UserInfoDisplay = ({ userData }: UserInfoDisplayInterface) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "GBP",
  });

  const dateOfBirth = dayjs(userData.dateOfBirth).format("DD/MM/YYYY");
  const registrationDateTime = dayjs(userData.registrationDateTimeUtc).format(
    "DD/MM/YY HH:mm:ss"
  );
  let hasVerifiedIdentity: string = "";
  let receiveMarketingEmails: string = "";
  let receiveMarketingSMS: string = "";
  let hasSignUpCode: string = "";
  let userSignUpCodeCompleteUtc: string = "";
  let selfExclusionEndDateTimeUtc: string = "";
  let coolOffEndDateTimeUtc: string = "";
  let userCompletedReferralUtc: string = "";
  let hasReferralCode: string = "";
  let thoroughfare: string = "";
  let postcode: string = "";
  let accountBalance = `${userData.accountBalance}`;
  let freeBetAccountBalance = `${userData.freeBetAccountBalance}`;

  if (userData.hasVerifiedIdentity === true) {
    hasVerifiedIdentity = "True";
  } else {
    hasVerifiedIdentity = "False";
  }

  if (userData.receiveMarketingEmails === true) {
    receiveMarketingEmails = "True";
  } else {
    receiveMarketingEmails = "False";
  }

  if (userData.receiveMarketingSMS === true) {
    receiveMarketingSMS = "True";
  } else {
    receiveMarketingSMS = "False";
  }

  if (userData.hasSignUpCode === "true") {
    hasSignUpCode = "True";
  } else {
    hasSignUpCode = "False";
  }

  if (userData.hasReferralCode === "true") {
    hasReferralCode = "True";
  } else {
    hasReferralCode = "False";
  }

  if (userData.userSignUpCodeCompleteUtc === null) {
    userSignUpCodeCompleteUtc = "N/A";
  } else {
    userSignUpCodeCompleteUtc = dayjs(
      userData.userSignUpCodeCompleteUtc
    ).format("DD/MM/YY HH:mm:ss");
  }

  if (userData.thoroughfare === null || userData.thoroughfare.length === 0) {
    thoroughfare = "N/A";
  } else {
    thoroughfare = userData.thoroughfare;
  }

  if (userData.postcode === null || userData.postcode.length === 0) {
    postcode = "N/A";
  } else {
    postcode = userData.postcode;
  }

  if (userData.coolOffEndDateTimeUtc === null) {
    coolOffEndDateTimeUtc = "N/A";
  } else {
    coolOffEndDateTimeUtc = dayjs(userData.coolOffEndDateTimeUtc).format(
      "DD/MM/YY HH:mm:ss"
    );
  }

  if (userData.selfExclusionEndDateTimeUtc === null) {
    selfExclusionEndDateTimeUtc = "N/A";
  } else {
    selfExclusionEndDateTimeUtc = dayjs(
      userData.selfExclusionEndDateTimeUtc
    ).format("DD/MM/YY HH:mm:ss");
  }

  if (userData.userCompletedReferralUtc === null) {
    userCompletedReferralUtc = "N/A";
  } else {
    userCompletedReferralUtc = dayjs(userData.userCompletedReferralUtc).format(
      "DD/MM/YY HH:mm:ss"
    );
  }

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", width: 250 },
    {
      field: "value",
      headerName: "Value",
      width: 250,
    },
  ];

  const rows = [
    { id: 0, name: "User ID", value: `${userData.userId}` },
    { id: 1, name: "Username", value: `${userData.username}` },
    { id: 2, name: "Forname", value: `${userData.forename}` },
    { id: 3, name: "Surname", value: `${userData.lastname}` },
    { id: 4, name: "Date Of Birth", value: `${dateOfBirth}` },
    { id: 5, name: "Mobile No.", value: `${userData.mobileNumber}` },
    { id: 6, name: "Email", value: `${userData.email}` },
    { id: 7, name: "Thoroughfare", value: `${thoroughfare}` },
    { id: 8, name: "Postcode", value: `${postcode}` },
    {
      id: 9,
      name: "Account Balance",
      value: formatter.format(Number(accountBalance)),
    },
    {
      id: 10,
      name: "Free Bet Account Balance",
      value: formatter.format(Number(freeBetAccountBalance)),
    },
    {
      id: 11,
      name: "Verified Identitiy",
      value: `${hasVerifiedIdentity}`,
    },
  ];

  const rows2 = [
    {
      id: 12,
      name: "Gamestop Exclusion",
      value: `${userData.gamstopExclusionType}`,
    },
    {
      id: 13,
      name: "Receives Emails",
      value: `${receiveMarketingEmails}`,
    },
    { id: 14, name: "Receives SMS", value: `${receiveMarketingSMS}` },
    {
      id: 15,
      name: "Registration Date/Time",
      value: `${registrationDateTime}`,
    },
    {
      id: 16,
      name: "Fantasy Pot Entries",
      value: `${userData.fantasyUserPotEntries}`,
    },
    {
      id: 17,
      name: "Selector Pot Entries",
      value: `${userData.selectorUserPotEntries}`,
    },
    {
      id: 18,
      name: "Self Exclusion End Date",
      value: `${selfExclusionEndDateTimeUtc}`,
    },
    {
      id: 19,
      name: "Cool Off End Date",
      value: `${coolOffEndDateTimeUtc}`,
    },
    {
      id: 20,
      name: "Has Signup Code",
      value: `${hasSignUpCode}`,
    },
    {
      id: 21,
      name: "User Signup Codes Complete UTC",
      value: `${userSignUpCodeCompleteUtc}`,
    },
    {
      id: 22,
      name: "Has Referral Codes",
      value: `${hasReferralCode}`,
    },
    {
      id: 23,
      name: "User Referral Codes Complete UTC",
      value: `${userCompletedReferralUtc}`,
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <DataGrid
          sx={{
            marginRight: "20px",
            "& .MuiDataGrid-columnHeaders": { display: "none" },
          }}
          autoHeight
          rows={rows}
          columns={columns}
          columnHeaderHeight={0}
          hideFooterPagination
          hideFooterSelectedRowCount
          disableRowSelectionOnClick
        />
        <DataGrid
          sx={{
            "& .MuiDataGrid-columnHeaders": { display: "none" },
          }}
          autoHeight
          rows={rows2}
          columns={columns}
          hideFooterPagination
          hideFooterSelectedRowCount
          disableRowSelectionOnClick
        />
      </Box>
    </>
  );
};

export default UserInfoDisplay;
