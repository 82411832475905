import React from "react";
import { ReactElement, useMemo, useState } from "react";
import { arrayMove, List } from "react-movable";
import { DraggableTable } from "../components/DraggableTable";
import { FeaturedBanners } from "../interfaces/Carousel";

const CarouselScreen = (): ReactElement => {
  return (
    <>
      <DraggableTable />
    </>
  );
};

export default CarouselScreen;
